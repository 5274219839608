export const golfClubs = [
    {
        "clubId": "99999",
        "name": "Muu seura / ei seuraa",
        "abbreviation": "MUU"
    },
    {
        "clubId": "142",
        "name": "Aavasaksa Golf",
        "abbreviation": "AaG"
    },
    {
        "clubId": "93",
        "name": "Ähtärin Golf",
        "abbreviation": "ÄG"
    },
    {
        "clubId": "92",
        "name": "Ålands Golfklubb",
        "abbreviation": "ÅGK"
    },
    {
        "clubId": "125",
        "name": "Alastaron Golf",
        "abbreviation": "AlGo"
    },
    {
        "clubId": "1",
        "name": "Anola Golf Club",
        "abbreviation": "AGC"
    },
    {
        "clubId": "74",
        "name": "Archipelagia Golf Club",
        "abbreviation": "ArGC"
    },
    {
        "clubId": "3",
        "name": "Aulangon Golfklubi",
        "abbreviation": "AGK"
    },
    {
        "clubId": "4",
        "name": "Aura Golf",
        "abbreviation": "AG"
    },
    {
        "clubId": "117",
        "name": "Aurinko Golf",
        "abbreviation": "AGN"
    },
    {
        "clubId": "147",
        "name": "Bjärkas Golf & Country Club",
        "abbreviation": "BGCC"
    },
    {
        "clubId": "5",
        "name": "Botnia Golf",
        "abbreviation": "BG"
    },
    {
        "clubId": "133",
        "name": "Draivin Golf & Country Club",
        "abbreviation": "DGCC"
    },
    {
        "clubId": "139",
        "name": "Eckerö Golf/Kyrkoby Golfklubb",
        "abbreviation": "KyGo"
    },
    {
        "clubId": "106",
        "name": "Eerikkala Golf",
        "abbreviation": "EeG"
    },
    {
        "clubId": "129",
        "name": "Eke Golf ry",
        "abbreviation": "EkeG"
    },
    {
        "clubId": "7",
        "name": "Espoo Ringside Golf",
        "abbreviation": "ERG"
    },
    {
        "clubId": "6",
        "name": "Espoon Golfseura",
        "abbreviation": "EGS"
    },
    {
        "clubId": "8",
        "name": "Etelä-Pohjanmaan Golf",
        "abbreviation": "EPG"
    },
    {
        "clubId": "145",
        "name": "GoGolf Turku",
        "abbreviation": "GGT"
    },
    {
        "clubId": "104",
        "name": "Golf Pirkkala",
        "abbreviation": "GPi"
    },
    {
        "clubId": "10",
        "name": "Golf Talma",
        "abbreviation": "GT"
    },
    {
        "clubId": "9",
        "name": "Golf-Porrassalmi",
        "abbreviation": "GP"
    },
    {
        "clubId": "11",
        "name": "Golf-Virrat",
        "abbreviation": "GV"
    },
    {
        "clubId": "12",
        "name": "Gumböle Golf",
        "abbreviation": "GG"
    },
    {
        "clubId": "13",
        "name": "Hangon Golf",
        "abbreviation": "HG"
    },
    {
        "clubId": "73",
        "name": "Harjattula Golf & Country Club",
        "abbreviation": "HGCC"
    },
    {
        "clubId": "103",
        "name": "Härmä Golf & Academy",
        "abbreviation": "HGA"
    },
    {
        "clubId": "14",
        "name": "Hartolan Golfklubi",
        "abbreviation": "HaGK"
    },
    {
        "clubId": "109",
        "name": "Hattula Golf",
        "abbreviation": "HaG"
    },
    {
        "clubId": "135",
        "name": "Helsingin Golf",
        "abbreviation": "HGM"
    },
    {
        "clubId": "15",
        "name": "Helsingin Golfklubi",
        "abbreviation": "HGK"
    },
    {
        "clubId": "16",
        "name": "Hiekkaharju Golf",
        "abbreviation": "HieG"
    },
    {
        "clubId": "123",
        "name": "HIFK Golf",
        "abbreviation": "HIFK"
    },
    {
        "clubId": "100",
        "name": "Hiisi-Golf",
        "abbreviation": "HSG"
    },
    {
        "clubId": "110",
        "name": "Hill Side Golf Club",
        "abbreviation": "HSGC"
    },
    {
        "clubId": "136",
        "name": "Himos-Patalahti Golf",
        "abbreviation": "HPG"
    },
    {
        "clubId": "111",
        "name": "Hirsala Golf",
        "abbreviation": "HirG"
    },
    {
        "clubId": "118",
        "name": "Hirvihaara Golf Mäntsälä",
        "abbreviation": "HiGM"
    },
    {
        "clubId": "132",
        "name": "Holiday Club Golf",
        "abbreviation": "HCGolf"
    },
    {
        "clubId": "18",
        "name": "Hyvinkään Golf",
        "abbreviation": "HyG"
    },
    {
        "clubId": "105",
        "name": "Iitin Golfseura",
        "abbreviation": "IGS"
    },
    {
        "clubId": "20",
        "name": "Imatran Golf",
        "abbreviation": "IG"
    },
    {
        "clubId": "144",
        "name": "Isosaari Golf & Yacht Club",
        "abbreviation": "IGYC"
    },
    {
        "clubId": "57",
        "name": "Jakobstads Golf - Pietarsaaren Golf",
        "abbreviation": "JPG"
    },
    {
        "clubId": "23",
        "name": "Järviseudun Golfseura",
        "abbreviation": "JGS"
    },
    {
        "clubId": "22",
        "name": "Jyväs-Golf",
        "abbreviation": "JG"
    },
    {
        "clubId": "130",
        "name": "Kaakon Golf",
        "abbreviation": "KaaG"
    },
    {
        "clubId": "24",
        "name": "Kajaanin Golf",
        "abbreviation": "KaG"
    },
    {
        "clubId": "146",
        "name": "KäkiGolf",
        "abbreviation": "KäG"
    },
    {
        "clubId": "25",
        "name": "Kalajokilaakson Golf",
        "abbreviation": "KLG"
    },
    {
        "clubId": "115",
        "name": "Kanavagolf Vääksy",
        "abbreviation": "KanG"
    },
    {
        "clubId": "62",
        "name": "Kankaisten Golf",
        "abbreviation": "KGM"
    },
    {
        "clubId": "26",
        "name": "Karelia Golf",
        "abbreviation": "KarG"
    },
    {
        "clubId": "27",
        "name": "Kartanogolf",
        "abbreviation": "KGOLF"
    },
    {
        "clubId": "28",
        "name": "Katinkulta Golf",
        "abbreviation": "KkG"
    },
    {
        "clubId": "84",
        "name": "Keimola Golf",
        "abbreviation": "KGV"
    },
    {
        "clubId": "40",
        "name": "Kemin Golf Klubi",
        "abbreviation": "KGK"
    },
    {
        "clubId": "29",
        "name": "Kerigolf",
        "abbreviation": "KeG"
    },
    {
        "clubId": "30",
        "name": "Kokkolan Golf",
        "abbreviation": "KoG"
    },
    {
        "clubId": "31",
        "name": "Konnus-Golf",
        "abbreviation": "KonG"
    },
    {
        "clubId": "32",
        "name": "Koski-Golf",
        "abbreviation": "KosG"
    },
    {
        "clubId": "137",
        "name": "Kotojärven Kartanogolf",
        "abbreviation": "KJKG"
    },
    {
        "clubId": "98",
        "name": "Kotojärvi Golf",
        "abbreviation": "KotoG"
    },
    {
        "clubId": "107",
        "name": "Kullo Golf Club",
        "abbreviation": "KGC"
    },
    {
        "clubId": "127",
        "name": "Kultaranta Golf Club Naantali",
        "abbreviation": "KGCN"
    },
    {
        "clubId": "102",
        "name": "Kuortane Golf",
        "abbreviation": "KuGo"
    },
    {
        "clubId": "33",
        "name": "Kurk Golf",
        "abbreviation": "Kurk"
    },
    {
        "clubId": "67",
        "name": "Kuusamo Golf",
        "abbreviation": "KuuG"
    },
    {
        "clubId": "35",
        "name": "Kymen Golf",
        "abbreviation": "KG"
    },
    {
        "clubId": "108",
        "name": "Kytäjä Golf",
        "abbreviation": "KyG"
    },
    {
        "clubId": "36",
        "name": "Lahden Golf",
        "abbreviation": "LG"
    },
    {
        "clubId": "83",
        "name": "Lakeside Golf Vammala",
        "abbreviation": "LGV"
    },
    {
        "clubId": "112",
        "name": "Lampila Golf",
        "abbreviation": "LaG"
    },
    {
        "clubId": "37",
        "name": "Laukaan Peurunkagolf",
        "abbreviation": "LPG"
    },
    {
        "clubId": "126",
        "name": "Lepaa Golf",
        "abbreviation": "LeG"
    },
    {
        "clubId": "141",
        "name": "Leppävirran Golfseura",
        "abbreviation": "LGS"
    },
    {
        "clubId": "122",
        "name": "Levi Golf",
        "abbreviation": "LeviG"
    },
    {
        "clubId": "140",
        "name": "Löfkulla Golf",
        "abbreviation": "LöG"
    },
    {
        "clubId": "39",
        "name": "Loimijoki Golf",
        "abbreviation": "LoG"
    },
    {
        "clubId": "41",
        "name": "Master Golf Club",
        "abbreviation": "Master"
    },
    {
        "clubId": "42",
        "name": "Meri-Lapin Golfklubi",
        "abbreviation": "MLGK"
    },
    {
        "clubId": "43",
        "name": "Meri-Teijo Golf",
        "abbreviation": "MTG"
    },
    {
        "clubId": "44",
        "name": "Messilän Golf",
        "abbreviation": "MeG"
    },
    {
        "clubId": "45",
        "name": "Mikkelin Golf",
        "abbreviation": "MG"
    },
    {
        "clubId": "64",
        "name": "Muuramen Golfseura",
        "abbreviation": "MGS"
    },
    {
        "clubId": "101",
        "name": "Nakkila Golf",
        "abbreviation": "NaG"
    },
    {
        "clubId": "46",
        "name": "Nevas Golf",
        "abbreviation": "NG"
    },
    {
        "clubId": "95",
        "name": "Nivalan Seudun Golf",
        "abbreviation": "NSG"
    },
    {
        "clubId": "66",
        "name": "Nokia River Golf",
        "abbreviation": "NRG"
    },
    {
        "clubId": "48",
        "name": "Nordcenter Golf & Country Club",
        "abbreviation": "NGCC"
    },
    {
        "clubId": "49",
        "name": "Nurmijärven Golfklubi",
        "abbreviation": "NGK"
    },
    {
        "clubId": "131",
        "name": "Oulujokilaakson Golfklubi",
        "abbreviation": "OjlG"
    },
    {
        "clubId": "50",
        "name": "Oulun Golfkerho",
        "abbreviation": "OGK"
    },
    {
        "clubId": "51",
        "name": "Outokummun Golfseura",
        "abbreviation": "OGS"
    },
    {
        "clubId": "52",
        "name": "Paltamon Golf",
        "abbreviation": "PaG"
    },
    {
        "clubId": "53",
        "name": "Peltola Golf",
        "abbreviation": "PeG"
    },
    {
        "clubId": "54",
        "name": "Peuramaa Golf Hjortlandet",
        "abbreviation": "PGH"
    },
    {
        "clubId": "55",
        "name": "Pickala Golf Club",
        "abbreviation": "PGC"
    },
    {
        "clubId": "56",
        "name": "Pielis-Golf",
        "abbreviation": "PG"
    },
    {
        "clubId": "58",
        "name": "Pirkanmaan Golf",
        "abbreviation": "PirG"
    },
    {
        "clubId": "148",
        "name": "Pitkäjärvi Golf Club",
        "abbreviation": "PGCK"
    },
    {
        "clubId": "19",
        "name": "Poltinkoski Golf",
        "abbreviation": "PkG"
    },
    {
        "clubId": "59",
        "name": "Porin Golfkerho",
        "abbreviation": "PGK"
    },
    {
        "clubId": "65",
        "name": "Porvoo Golf - Borgå Golf",
        "abbreviation": "PBG"
    },
    {
        "clubId": "120",
        "name": "PuulaGolf",
        "abbreviation": "PuulaG"
    },
    {
        "clubId": "61",
        "name": "Raahentienoon Golf",
        "abbreviation": "RTG"
    },
    {
        "clubId": "71",
        "name": "Rantasalmi Golf",
        "abbreviation": "RanG"
    },
    {
        "clubId": "63",
        "name": "Rauma Golf",
        "abbreviation": "RG"
    },
    {
        "clubId": "114",
        "name": "Remson Golf Club",
        "abbreviation": "RGC"
    },
    {
        "clubId": "113",
        "name": "Revontuli Golf",
        "abbreviation": "ReG"
    },
    {
        "clubId": "68",
        "name": "Ruukkigolf",
        "abbreviation": "RuG"
    },
    {
        "clubId": "69",
        "name": "Salo Golf",
        "abbreviation": "SaG"
    },
    {
        "clubId": "2",
        "name": "Santa Claus Golf Club",
        "abbreviation": "SantaGC"
    },
    {
        "clubId": "70",
        "name": "Sarfvik Golfklubi",
        "abbreviation": "SGC"
    },
    {
        "clubId": "72",
        "name": "Sea Golf Rönnäs",
        "abbreviation": "SGR"
    },
    {
        "clubId": "75",
        "name": "St. Lake Golf Club",
        "abbreviation": "StLGC"
    },
    {
        "clubId": "76",
        "name": "St. Laurence Golf",
        "abbreviation": "StLG"
    },
    {
        "clubId": "77",
        "name": "Suur-Helsingin Golf",
        "abbreviation": "SHG"
    },
    {
        "clubId": "143",
        "name": "Syväri Golf",
        "abbreviation": "SyG"
    },
    {
        "clubId": "47",
        "name": "Tahko Golf",
        "abbreviation": "TGS"
    },
    {
        "clubId": "78",
        "name": "Tammer-Golf",
        "abbreviation": "TG"
    },
    {
        "clubId": "134",
        "name": "Tapiola Golf",
        "abbreviation": "TaG"
    },
    {
        "clubId": "60",
        "name": "Tarinagolf",
        "abbreviation": "Tarina"
    },
    {
        "clubId": "79",
        "name": "Tawast Golf",
        "abbreviation": "TawG"
    },
    {
        "clubId": "128",
        "name": "Tiirin Golf",
        "abbreviation": "TiGo"
    },
    {
        "clubId": "80",
        "name": "Tuusulan Golfklubi",
        "abbreviation": "TGK"
    },
    {
        "clubId": "81",
        "name": "Uudenkaupungin Golfklubi",
        "abbreviation": "UGK"
    },
    {
        "clubId": "82",
        "name": "Vaasan Golf",
        "abbreviation": "VaG"
    },
    {
        "clubId": "116",
        "name": "Vanajanlinna Golf & Country Club",
        "abbreviation": "VLG"
    },
    {
        "clubId": "138",
        "name": "Vantaan Golfpuisto",
        "abbreviation": "VaGo"
    },
    {
        "clubId": "86",
        "name": "Vierumäen Golfseura",
        "abbreviation": "ViGS"
    },
    {
        "clubId": "96",
        "name": "Vihti Golf Club",
        "abbreviation": "VGC"
    },
    {
        "clubId": "87",
        "name": "Viipurin Golf",
        "abbreviation": "VG"
    },
    {
        "clubId": "124",
        "name": "Virpiniemi Golf Club",
        "abbreviation": "ViGC"
    },
    {
        "clubId": "88",
        "name": "Virvik Golf",
        "abbreviation": "ViG"
    },
    {
        "clubId": "119",
        "name": "Vörå Golf - Vöyrin Golf",
        "abbreviation": "VöG"
    },
    {
        "clubId": "99",
        "name": "Vuosaari Golf Helsinki",
        "abbreviation": "VGH"
    },
    {
        "clubId": "99998",
        "name": "Wise Club",
        "abbreviation": "WIS"
    },
    {
        "clubId": "89",
        "name": "Wiurila Golf & Country Club",
        "abbreviation": "WGCC"
    },
    {
        "clubId": "90",
        "name": "Ylä-Savon Golfseura",
        "abbreviation": "YSG"
    },
    {
        "clubId": "91",
        "name": "Yyteri Golf",
        "abbreviation": "YG"
    }
]