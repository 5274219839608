import { Diagnostic } from '@awesome-cordova-plugins/diagnostic';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { alertController } from "@ionic/vue";
import { mapState, mapMutations } from "vuex";
import axios from 'axios';

// Get device platform (iOS / Android)
const device = Capacitor.getPlatform();

export default {
	computed: {
		...mapState({
			settings: state => state.common.settings,
			userAnswerToLocationAuthorizationDenied: state => state.door.userAnswerToLocationAuthorizationDenied,
			locationWarningShown: state => state.door.locationWarningShown,
			doorToOpenReaderId: state => state.door.doorToOpenReaderId,
			doorToOpenRangedBeacons: state => state.door.doorToOpenRangedBeacons,
			doorDataSelectedLocationId: state => state.door.doorDataSelectedLocationId,
			userCurrentCordinates: state => state.door.userCurrentCordinates,
			user: state => state.user.user,
			doorAndLocationData: state => state.door.doorAndLocationData
		}),
	},
	methods: {
		...mapMutations({
			mutateLocationWarningShown: "door/mutateLocationWarningShown",
			mutateUserAnswerToLocationAuthorizationDenied: "door/mutateUserAnswerToLocationAuthorizationDenied"
		}),

		async saveDoorOpenAccessLog(logMessage) {
			const deviceInfo = await Device.getInfo();
			const doorAccessLogData = {
				personId: this.user.personId,
				locationId: this.doorDataSelectedLocationId,
				readerId: this.doorToOpenReaderId,
				fullDeviceData: deviceInfo,
				currentCoords: this.userCurrentCordinates ? this.userCurrentCordinates.coords : false,
				lastRangedBeacons: this.doorToOpenRangedBeacons,
				doOpenDoor: true, // Is the door really opening? This is used in development and testing.
				eventErrorDescription: logMessage,
				appVersion: this.settings.appVersion,
			};

			try {
				const { data } = await axios.post(`${this.activeHost.ajaxUrl}?controller=ajax&doorcustomevent=1&appauth=${this.activeHost.appauth}`, doorAccessLogData)
				console.log("saveDoorOpenAccessLog response:", data)
			} catch (error) {
				if (error.response) {
					console.error(error.response.data);
				} else {
					console.error(error)
				}
			}
		},
		// Check if location services are authorized for app and turned on.
		// RETURNS {isLocationAvailable: (boolean), isEnabled: (boolean/null), isAuthorized: (boolean)}
		async checkDeviceLoactionAvailability(doorOpen = false) {
			// console.log("checkDeviceLoactionAvailability(): RUN - doorOpen:", doorOpen)
			// Abort if user denied location aythorization to prevent infinite loop (ABORT ONLY IF NOT OPENING DOOR)
			if (this.userAnswerToLocationAuthorizationDenied === true && doorOpen === false) {
				return {
					isLocationAvailable: false,
					isEnabled: null,
					isAuthorized: false
				}
			}

			try {
				// If not on real device skip location check
				if (device === "web") {
					return {
						isLocationAvailable: false,
						isEnabled: null,
						isAuthorized: false
					}
				}

				// BUG CHECK IF UPDATE TO @ionic-native/diagnostic, when writing this current version is 5.35.0
				// On iOS not possbile to check locationAccuracyAuthorization (https://github.com/dpa99c/cordova-diagnostic-plugin#locationaccuracyauthorization-constants).
				// # FULL - The user authorized the app to access location data with full accuracy.
				// # REDUCED - The user authorized the app to access location data with reduced accuracy (~1-20 km).
				// https://github.com/ionic-team/ionic-native/issues/3624

				// iOS returns getLocationAuthorizationStatus: denied_always if locationServices are turned off.
				// So let's first check are location services turned on on iOS device
				if (device === "ios") {
					const isLocationEnabled = await Diagnostic.isLocationEnabled()
					if (isLocationEnabled === false) {
						// Show error location services turned off
						this.offerLocationServicesSettings(doorOpen)
						return {
							isLocationAvailable: false,
							isEnabled: false,
							isAuthorized: false
						}
					}
				}

				// Get current authorization status
				const locationAuthorizationStatus = await Diagnostic.getLocationAuthorizationStatus()
				//console.log("getLocationAuthorizationStatus: " + locationAuthorizationStatus)

				// If user previously refused authorization permanently, offer to open settings.
				if (locationAuthorizationStatus.toUpperCase() === "DENIED_ALWAYS") {
					this.offerLocationServicesSettings(doorOpen, true, true)
					return {
						isLocationAvailable: false,
						isEnabled: null,
						isAuthorized: false
					}
				}

				// If permission not yet granted, and user has not permanently refused before, ask it now.
				if (["NOT_REQUESTED", "DENIED_ONCE", "NOT_DETERMINED"].includes(locationAuthorizationStatus.toUpperCase())) {
					const userAnswerToLocationAuthorization = await Diagnostic.requestLocationAuthorization()
					//console.log("userAnswerToLocationAuthorization:", userAnswerToLocationAuthorization)

					if (["DENIED_ALWAYS", "DENIED_ONCE"].includes(userAnswerToLocationAuthorization.toUpperCase())) {
						// Set user denied location authorization (to prevet infinite loop asking permission)
						this.mutateUserAnswerToLocationAuthorizationDenied(true)
					}

					// If we still don't have permission even after above request. Show error to user and abort.
					if (!["GRANTED", "GRANTED_WHEN_IN_USE", "AUTHORIZED_WHEN_IN_USE", "AUTHORIZED"].includes(userAnswerToLocationAuthorization.toUpperCase())) {
						this.showAlert(this.$t("locationServices.errorTitle"), this.$t("locationServices.errorAppNotWorkingWithout"))
						if(doorOpen === true) {
							this.saveDoorOpenAccessLog(`User did not authorized to use location final state was: ${userAnswerToLocationAuthorization.toUpperCase()}`)
						}
						return {
							isLocationAvailable: false,
							isEnabled: null,
							isAuthorized: false
						}
					}
				}

				// If we reach here, we should have authorization now.
				if (device === "ios") {
					// We have location available reset, warnings
					this.mutateUserAnswerToLocationAuthorizationDenied(false)
					this.mutateLocationWarningShown(false)
					return {
						isLocationAvailable: true,
						isEnabled: true,
						isAuthorized: true
					}
				}

				// Next check if location services are actually turned on for Android.
				if (device === "android") {
					const getLocationMode = await Diagnostic.getLocationMode()
					//console.log("getLocationMode:", getLocationMode)

					if (getLocationMode.toUpperCase() === "LOCATION_OFF") {
						// Offer settings to turn location on
						this.offerLocationServicesSettings(doorOpen)
						return {
							isLocationAvailable: false,
							isEnabled: false,
							isAuthorized: true
						}
					}

					if (["HIGH_ACCURACY", "DEVICE_ONLY"].includes(getLocationMode.toUpperCase())) {
						// We have location available reset, warnings
						this.mutateUserAnswerToLocationAuthorizationDenied(false)
						this.mutateLocationWarningShown(false)
						return {
							isLocationAvailable: true,
							isEnabled: true,
							isAuthorized: true
						}
					}
				}

				// If we reach here, we have unknown GPS error
				this.showAlert(this.$t("locationServices.errorTitle"), this.$t("locationServices.error.unknownGPSError"))
				console.error("Unknown GPS error.")
				this.saveDoorOpenAccessLog(`Unknown GPS error.`)
				return {
					isLocationAvailable: false,
					isEnabled: false,
					isAuthorized: true
				}
			} catch (error) {
				console.error(error)
			}
		},
		// Handles location services errors and offers settings.
		async offerLocationServicesSettings(doorOpen, authorizationStatus = false, deniedAlways = false) { // If doorOpen = true, show error even of already shown
			//console.log("locationWarningShown:", this.locationWarningShown, "doorOpen:", doorOpen)

			// If location warning already shown no need to show again unless user is trying to open door
			if (this.locationWarningShown === true && doorOpen === false) {
				return
			}

			// Location services not available or not authorized, offer settings for user
			let errorMessage;
			const gpsUsedFor = this.$t("locationServices.areUsedFor")
			const wantToTurnOnGps = this.$t("locationServices.wantTurnOn")

			// COMPOSE ERROR MESSAGES
			// If authorization denied
			if (deniedAlways === true) errorMessage = `${this.$t("locationServices.error.locationServicesNotAuthorized")} <br><br> ${gpsUsedFor} <br><br> ${wantToTurnOnGps}`
			// If location services turned off
			if (deniedAlways === false) errorMessage = `${authorizationStatus ? this.$t("locationServices.notEnabledText") : this.$t("locationServices.error.locationServicesNotEnabled")} <br><br> ${gpsUsedFor} <br><br> ${wantToTurnOnGps}`

			this.mutateLocationWarningShown(true)

			// Show confirm to go location services settings settings or cancel
			const alert = await alertController.create({
				header: this.$t("locationServices.errorTitle"),
				message: errorMessage,
				backdropDismiss: false,
				buttons: [
					{
						text: this.$t("locationServices.cancelButton"),
						handler: async () => {
							// User denies to go location services settings. Notify user app will not work without location services.
							// Not work without authorization
							if (authorizationStatus === true) {
								this.showAlert(this.$t("locationServices.errorTitle"), this.$t("locationServices.error.appNotWorkWithoutPermission"))
								if(doorOpen === true) {
									this.saveDoorOpenAccessLog("Location services are not authorized and user denies to go APP settings.")
								}
							}
							// Not work if turned off
							if (authorizationStatus === false) {
								this.showAlert(this.$t("locationServices.errorTitle"), this.$t("locationServices.error.appNotWorkWithoutLocationServices"))
								if(doorOpen === true) {
									this.saveDoorOpenAccessLog("Location services are turned off and user denies to go APP settings.")
								}
							}
						},
					},
					{
						text: this.$t("locationServices.confirmButton"),
						handler: () => {
							// Switch to app settings
							if (authorizationStatus === true) {
								// Not authorized
								if(doorOpen === true) {
									this.saveDoorOpenAccessLog("Location services are not authorized and user chose to go APP settings.")
								}
								Diagnostic.switchToSettings()
							} else {
								// Location services turned off
								if(doorOpen === true) {
									this.saveDoorOpenAccessLog("Location services are turned off and user choose to go APP settings.")
								}
								// Android switch to location settings
								if (device === "android") Diagnostic.switchToLocationSettings()
								// iOS switch to app settings
								else Diagnostic.switchToSettings()
							}
						},
					},
				],
			});
			return alert.present();
		},
		// Check if Bluetooth is available and turned on.
		// RETURNS {isBluetoothAvailable: (boolean), getBluetoothState: (string/null)}
		async bluetoothAvailability() {
			// If not on real device skip bluetooth check
			if (device === "web") {
				return {
					isBluetoothAvailable: false,
					getBluetoothState: null
				}
			}
			try {
				//console.log("bluetoothAvailability(): RUN")
				// Get all needed device diagnostics
				const deviceDiagnostics = await getDeviceDiagnostics()
				//console.log(JSON.stringify(deviceDiagnostics))

				let getBluetoothState = deviceDiagnostics.getBluetoothState.toUpperCase()

				// Diagnostic plugin has some issues detecting bluetooth state from iOS on first run
				// If on first run getBluetoothState = UNKNOWN, re-run getBluetoothState
				if (getBluetoothState.toUpperCase() === "UNKNOWN") {
					console.log("re run getBluetoothState")
					getBluetoothState = await reRunGetBluetoothStateWithDelay(500) // Using delay of 500ms to re-run
					getBluetoothState = getBluetoothState.toUpperCase()
					console.log("reRunGetBluetoothState:", getBluetoothState)
					// If still UNKNOWN abort, bluetooth unavailable
					if (getBluetoothState.toUpperCase() === "UNKNOWN" || getBluetoothState.toUpperCase() === "UNSUPPORTED" ) {
						console.error("BLUETOOTH STATE: UNKNOWN OR UNSUPPORTED")
						return {
							isBluetoothAvailable: false,
							getBluetoothState
						}
					}
				}

				// If bluetooth is not turned on (Android) or not authorized (iOS)
				if (["POWERED_OFF", "UNAUTHORIZED", "UNSUPPORTED"].includes(getBluetoothState.toUpperCase())) {
							return {
								isBluetoothAvailable: false,
								getBluetoothState
							}
					}

					// if (device === "ios") {
					// 	Diagnostic.registerBluetoothStateChangeHandler(function (state) {
					// 		console.log("BluetoothStateChange:", state)
					// 	})

					// 	// FIXME: Not working like should? When bluetooth disabled wont ask authorization.
					// 	// https://github.com/dpa99c/cordova-diagnostic-plugin#requestbluetoothauthorization
					// 	const requestBluetoothAuthorization = await Diagnostic.requestBluetoothAuthorization()
					// 	console.log("requestBluetoothAuthorization:", requestBluetoothAuthorization)

					// 	// If no permission to use bluetooth return false
					// 	if (getBluetoothState.toUpperCase() === "UNAUTHORIZED", "UNSUPPORTED") {
					// 		return {
					// 			isBluetoothAvailable: false,
					// 			getBluetoothState
					// 		}
					// 	}
					// }

				// Verify bluetooth is now on, if not return false
				if (!["POWERING_ON", "POWERED_ON"].includes(getBluetoothState.toUpperCase())) {
					return {
						isBluetoothAvailable: false,
						getBluetoothState
					}
				}

				// If we reachd here we have bluetooth available
				return {
					isBluetoothAvailable: true,
					getBluetoothState
				}

			} catch (error) {
				console.error(error)
			}
		},
		// Handles Bluetooth errors and offers settings. RETURN Promise
		handleBluetoothNotAvailableErrors(bluetoothState) {
			return new Promise((resolve) => {
				// Show error bluetooth not turned on or not authorized
				if (["POWERED_OFF", "UNSUPPORTED", "POWERING_OFF", "RESETTING","UNAUTHORIZED"].includes(bluetoothState.toUpperCase())) {
					this.showToast(this.$t("bluetoothServices.turnedOffToast"), this.$t("bluetoothServices.turnedOffToastBody"), "warning")
					resolve({ continueWithoutBluetooth: true })
				}

				// Unknown bluetooth error
				else {
					alertController.create({
						header: this.$t("bluetoothServices.warning.title"),
						message: `${this.$t("bluetoothServices.error.unknownError")} <br><br> ${this.$t("bluetoothServices.error.searchOnlyGPS")}`,
						buttons: [
							{
								text: "Ok",
								handler: async () => {
									// Continue after user confirm bluetooth error
									resolve({ continueWithoutBluetooth: true })
								},
							},
						],
					}).then(alert => {
						alert.present()
					});
				}
			})
		},

		// Checks if current cordinates are inside devLocation or locationArea
		// RETURNS {isInsideArea: (boolean), isInsideDevArea: (boolean)}
		containsLocationCheck(position, locationArea, devLocation = false) {

			// If mixin function containsLocationCheck() is called from vuex module it will not have state data
			let devlocationCordinates = this?.settings?.devLocation
			if(devLocation !== false) {
				devlocationCordinates = devLocation
			}

			// Validate position coords are numbers
			if (!(typeof (position?.coords?.latitude) === "number" && typeof (position?.coords?.longitude))) {
				console.error("containsLocationCheck: position coords are not numbers")
				return { isInsideArea: false, isInsideDevArea: false }
			}
			let isInsideDevArea = false

			// Check if we have a devLocation in settings
			if (typeof (devlocationCordinates) === "object") {
				// Check if we are inside devLocation
				const latLng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
				const areaPolygon = new window.google.maps.Polygon({
					paths: devlocationCordinates.map(latLng => new window.google.maps.LatLng(latLng.lat, latLng.lng))
				})
				isInsideDevArea = window.google.maps.geometry.poly.containsLocation(latLng, areaPolygon)
			}

			// Check if we are inside locationArea
			const latLng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
			const areaPolygon = new window.google.maps.Polygon({
				paths: locationArea.map(latLng => new window.google.maps.LatLng(latLng.lat, latLng.lng))
			})
			const isInsideArea = window.google.maps.geometry.poly.containsLocation(latLng, areaPolygon)
			return { isInsideArea: isInsideArea, isInsideDevArea: isInsideDevArea }
		},

		getLocationNameByLocationId(locationId) {
			if (locationId === null) {
				return this.$t("wiseGymcard.doorOpen.chooseLocation")
			}
			return this.doorAndLocationData.locations.find(item => item.locationId === locationId).locationName
		},
		getDoorNameByReaderId(readerId) {
			const getCurrentlocaleDoorName = this.doorAndLocationData.readers.find(door => door.readerId === readerId).readerLang[this.userLocale]?.doorName
			if (typeof getCurrentlocaleDoorName !== "undefined") {
				return getCurrentlocaleDoorName
			} else {
				// Fallback to doorname lang fi-FI if user locale returns undefined
				return this.doorAndLocationData.readers.find(door => door.readerId === readerId).readerLang[this.settings.bluetoothFallbackLocale]?.doorName
			}
		},
	},
};

// HELPER FUNCTIONS

// Diagnostic plugin has some issues detecting bluetooth state from iOS on first run
const reRunGetBluetoothStateWithDelay = (delay) => {
	return new Promise((resolve) => setTimeout(async () => resolve(await Diagnostic.getBluetoothState()), delay))
}
const getDeviceDiagnostics = async () => {
	return new Promise((resolve) => {
		const isAndroid = device === "android" ? true : false
		const diagnosticsPromises = [];

		//Checks if app is able to access device location.
		//Platforms: Android, iOS
		diagnosticsPromises[0] = new Promise((resolve) => Diagnostic.isLocationAvailable(result => resolve(result), error => resolve(error)));

		//Checks if Bluetooth is available to the app. Returns true if the device has Bluetooth capabilities AND if Bluetooth setting is switched on.
		//Platforms: Android, iOS
		diagnosticsPromises[1] = new Promise((resolve) => Diagnostic.isBluetoothAvailable(result => resolve(result), error => resolve(error)));

		//Returns true if the device setting for location is on. On Android this returns true if Location Mode is switched on. On iOS this returns true if Location Services is switched on.
		//Platforms: Android and iOS
		diagnosticsPromises[2] = new Promise((resolve) => Diagnostic.isLocationEnabled(result => resolve(result), error => resolve(error)));

		//Checks if the application is authorized to use location.
		//Platforms: Android and iOS
		diagnosticsPromises[3] = new Promise((resolve) => Diagnostic.isLocationAuthorized(result => resolve(result), error => resolve(error)));

		//Returns the location authorization status for the application.
		//Platforms: Android and iOS
		diagnosticsPromises[4] = new Promise((resolve) => Diagnostic.getLocationAuthorizationStatus(result => resolve(result), error => resolve(error)));

		//Returns the state of Bluetooth on the device.
		//Platforms: Android and iOS
		diagnosticsPromises[5] = new Promise((resolve) => Diagnostic.getBluetoothState(result => resolve(result), error => resolve(error)));

		//Checks if high-accuracy locations are available to the app from GPS hardware. Returns true if Location mode is enabled and is set to "Device only" or "High accuracy" AND if the app is authorised to use location.
		//Platforms: Android
		diagnosticsPromises[6] = isAndroid ? new Promise((resolve) => Diagnostic.isGpsLocationAvailable(result => resolve(result), error => resolve(error))) : new Promise((resolve) => resolve(false));

		//Checks if the device location setting is set to return high-accuracy locations from GPS hardware. Returns true if Location mode is enabled and is set to either Device only or High accuracy.
		//Platforms: Android
		diagnosticsPromises[7] = isAndroid ? new Promise((resolve) => Diagnostic.isGpsLocationEnabled(result => resolve(result), error => resolve(error))) : new Promise((resolve) => resolve(false));

		//Returns the current location mode setting for the device.
		//Platforms: Android.
		diagnosticsPromises[8] = isAndroid ? new Promise((resolve) => Diagnostic.getLocationMode(result => resolve(result), error => resolve(error))) : new Promise((resolve) => resolve(false));

		//Checks if the device setting for Bluetooth is switched on.
		//Platforms: Android
		diagnosticsPromises[9] = isAndroid ? new Promise((resolve) => Diagnostic.isBluetoothEnabled(result => resolve(result), error => resolve(error))) : new Promise((resolve) => resolve(false));

		//Checks if the device has Bluetooth capabilities.
		//Platforms: Android
		diagnosticsPromises[10] = isAndroid ? new Promise((resolve) => Diagnostic.hasBluetoothLESupport(result => resolve(result), error => resolve(error))) : new Promise((resolve) => resolve(false));

		Promise.all(diagnosticsPromises.map(p => p.catch(() => undefined))).then(diagnosticsResults => {
			const resolveObject = {
				isLocationAvailable: diagnosticsResults[0],
				isBluetoothAvailable: diagnosticsResults[1],
				isLocationEnabled: diagnosticsResults[2],
				isLocationAuthorized: diagnosticsResults[3],
				getLocationAuthorizationStatus: diagnosticsResults[4],
				getBluetoothState: diagnosticsResults[5],
				isGpsLocationAvailable: isAndroid ? diagnosticsResults[6] : diagnosticsResults[0], //For iOS, use isLocationAvailable
				isGpsLocationEnabled: isAndroid ? diagnosticsResults[7] : diagnosticsResults[2], //For iOS, use isLocationEnabled
				getLocationMode: isAndroid ? diagnosticsResults[8] : false,
				isBluetoothEnabled: isAndroid ? diagnosticsResults[9] : (diagnosticsResults[5] === Diagnostic.bluetoothState.POWERED_ON), //For iOS, set true if powered_on
				hasBluetoothLESupport: isAndroid ? diagnosticsResults[10] : true, //iOS always has LE support
			};
			resolve(resolveObject)
		});
	});
}
