import { ref } from 'vue'
// data and functions for app state that are not Wise-specific
export const appState = {
    runners: ref([]),
}

/**
 * 
 * @param {Function} runner a function to run on app change
 * @param {Object} settings
 */
export function addRunner(runner, settings = { isActive: true }) {
    appState.runners.value.push({
        method: runner,
        ...settings,
    })
}

/**
 * 
 * @param {Object} settings 
 */
export function run(settings = { isActive: true }) {
    const runners = appState.runners.value.filter(row => {
        const conditions = Object.keys(row).filter(name => name !== 'method');

        for (let i = 0;i < conditions.length;i++) {
            const name = conditions[i];

            if (settings[name] !== row[name]) {
                return false;
            }
        }

        return true;
    });

    runners.forEach((row, i) => {
        row.method();

        if (row.once) {
            appState.runners.splice(i, 1);
        }
    })
}