import axios from 'axios';
import { Geolocation } from '@capacitor/geolocation';

// Used for to populate door state on initial load and reset door state on logout
const getDoorDefaultState = () => {
  return {
		doorToOpenReaderId: null, // Used for saveDoorOpenAccessLog
		doorToOpenRangedBeacons: null, // Used for saveDoorOpenAccessLog
		doorAndLocationData: null,
		doorDataSelectedLocationId: null,
		userCurrentCordinates: null,
		locationWarningShown: false,
		userAnswerToLocationAuthorizationDenied: false,
		successfulLocationValidation: { timestamp: null, cordinates: null, beacon: null, locationId: null },
		doorOpenFailures: [], // {timestamp: null, locationId: null}
		beaconsInRanging: null,
		iBeaconDelegate: null
  }
}

export default {
	namespaced: true,
	state: getDoorDefaultState(),
	getters: {
	},
	mutations: {
		mutateDoorStateToDefault(state) {
			Object.assign(state, getDoorDefaultState())
		},
		mutateDoorToOpenReaderId(state, doorToOpenReaderId) {
			state.doorToOpenReaderId = doorToOpenReaderId;
		},
		mutateDoorToOpenRangedBeacons(state, doorToOpenRangedBeacons) {
			state.doorToOpenRangedBeacons = doorToOpenRangedBeacons;
		},
		mutateDoorAndLocationData(state, doorAndLocationData) {
			state.doorAndLocationData = doorAndLocationData;
			//console.log("Door and location data in state: ", state.doorAndLocationData)
		},
		mutateDoorDataSelectedLocationId(state, doorDataSelectedLocationId) {
			state.doorDataSelectedLocationId = doorDataSelectedLocationId;
		},
		mutateUserCurrentCordinates(state, userCurrentCordinates) {
			state.userCurrentCordinates = userCurrentCordinates;
		},
		mutateLocationWarningShown(state, locationWarningShown) {
			state.locationWarningShown = locationWarningShown;
		},
		mutateUserAnswerToLocationAuthorizationDenied(state, userAnswerToLocationAuthorizationDenied) {
			state.userAnswerToLocationAuthorizationDenied = userAnswerToLocationAuthorizationDenied;
		},
		mutateSuccessfulLocationValidation(state, beaconAndLocationId) {
			state.successfulLocationValidation = {
				timestamp: Date.now() / 1000 | 0, // Set timestamp in seconds
				cordinates: state.userCurrentCordinates?.coords ? state.userCurrentCordinates.coords : null,
				beacon: beaconAndLocationId.beacon,
				locationId: beaconAndLocationId.locationId
			}
		},
		mutateDoorOpenFailures(state, locationId) {
			if (locationId === null) {
				// Reset failures
				state.doorOpenFailures = []
			} else {
				// Add failure to array
				state.doorOpenFailures.push(
					{
						timestamp: Date.now() / 1000 | 0, // Set timestamp in seconds
						locationId
					}
				)
			}
		},
		mutateUserbeaconsInRanging(state, beaconsInRanging) {
			state.beaconsInRanging = beaconsInRanging;
		},
		mutateIBeaconDelegate(state, iBeaconDelegate) {
			state.iBeaconDelegate = iBeaconDelegate;
		},
	},
	actions: {
		async getDoorAndLocationDataFromServer({ rootGetters, commit }) {
			try {
				const args = {
					includePaused: true
				}
				const { data } = await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?controller=ajax&getaccesscontrolappdata=1&appauth=${rootGetters["common/activeHost"].appauth}`, args)
				commit('mutateDoorAndLocationData', data)

				// If door data has only one location set locationId
				if (data.locations.length === 1) {
					commit('mutateDoorDataSelectedLocationId', data.locations[0].locationId)
				}
				return data
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getPosition({ commit}) {
			try {
				const coordinates = await Geolocation.getCurrentPosition({ enableHighAccuracy:true });
				commit('mutateUserCurrentCordinates', coordinates)
			} catch (error) {
				console.error("getPosition()", error.message)
			}
		}
	}
}