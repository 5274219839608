<template>
	<wise-page>
		<div>
			<ion-button 
				expand="block"
				color="primary"
				size="large"
				@click="openLoginModal()">
				{{ $t('login.login') }}
			</ion-button>
			<ion-button 
				expand="block"
				color="primary"
				size="large"
				router-link="register">
				{{ $t('login.register') }}
			</ion-button>
		</div>
	</wise-page>
</template>

<script>
import LoginModal from "@/WiseCore/components/WiseLoginModal.vue";
import { enterOutline, createOutline } from "ionicons/icons";
import { modalController } from "@ionic/vue";

export default {
	name: "WiseFrontLogin",
	data() {
		return {
			enterOutline, createOutline
		}
	},
	methods: {
		async openLoginModal() {
			const modal = await modalController.create({
				component: LoginModal,
				cssClass: "wise-login-modal",
				// mode: 'md',
				componentProps: {
					title: this.$t("login.login"),
				},
			});
			return modal.present();
		},
	},
};
</script>
