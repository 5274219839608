import axios from 'axios'
import { param } from './helpers.js'
let settings = {};

export default {
    setSettings({ baseUrl, restUrl, ecomUrl, ajaxUrl, adminUrl, appauth }) {
        settings = { baseUrl, restUrl, ecomUrl, ajaxUrl, adminUrl, appauth };
    },
    /**
     * By default returns only the user's player information
     * 
     * parameters: memberno, clubid, name(?)
     * 
     * @param {Object} params
     * @returns {Promise}
     */
    getPlayers(params = {}) {
        let queryString = '';
        
        if (Object.keys(params).length > 0) {
            queryString = '?'+param(params);
        }
        
        return axios({
            url: `${ settings.restUrl }/golf/player/${ queryString }`,
            method: 'GET',
            withCredentials: true,
        })
    },
    
    /**
     * 
     * @param {Object} params
     * @returns {Promise}
     */
    getClubs(params = {}) {
        let queryString = '';
        
        if (Object.keys(params).length > 0) {
            queryString = '?'+param(params);
        }
        
        return axios({
            url: `${ settings.restUrl }/golf/club/${ queryString }`,
            method: 'GET',
            withCredentials: true,
        })
    },

    /**
     * 
     * @param {Object} data 
     * @returns 
     */
    saveVisitorPlayer(data) {
        const params = {};
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (typeof window.product !== 'undefined') {
            const { productId } = window.product;

            params.productid = productId;
        }

        if (Object.keys(params).length > 0) {
            queryString = '?'+param(params);
        }

        return axios({
            url: `${ settings.restUrl }/golf/visitorplayer/${ queryString }`,
            method: 'POST',
            withCredentials: true,
            data
        })
    },
    
    saveGolfPlayerDetails(data) {
        const params = {
            reservations: 'golfplayerdetails'
        };
        let queryString = '';
        
        params.appauth = settings.appauth;

        if (Object.keys(params).length > 0) {
            queryString = '?'+param(params);
        }

        return axios({
            url: `${ settings.ajaxUrl }${ queryString }`,
            method: 'POST',
            //withCredentials: true,
            data
        })
    }
}