<template>
	<div id="wise-login-form">
		<div class="main-logo ion-padding-start ion-padding-end ion-margin-top ion-margin-bottom">
			<!--<img src="@/assets/logo.png" />-->
		</div>
		<!-- Show info chip when user access route with no permission -->
		<ion-chip v-if="urlToOnSuccess !== false" class="ion-margin ion-padding" color="warning">
			<ion-label class="ion-padding">{{ accessTokenUnvalid ? $t("login.acessTokenUnvalid") : $t("login.loginRequired") }}</ion-label>
			<i class="wn-icon lock-closed"></i>
		</ion-chip>
		
		<form :class="{ 'ion-margin-top': urlToOnSuccess !== false }" 
			@submit.prevent="submitLoginForm()" 
			v-if="!(forgotPasswordSuccess === true && forgotPassword === true)">
			<ion-item v-if="availableHosts.length > 1" lines="none" class="ion-no-padding">
				<ion-label class="ion-text-center ion-margin-bottom" position="stacked">{{ $t(loginModalHostText) }}</ion-label>
				<select class="ion-margin-top ion-margin-bottom" @change="setHost($event)">
					<option value="">{{ $t(loginModalSelectText) }}</option>
					<option v-for="host in availableHosts" :key="host.name" :value="host.name" :selected="selectedHost === host.name ? true : false">{{ host.displayChainName }}</option>
				</select>
			</ion-item>

			<ion-item v-if="forgotPassword" lines="none" class="ion-no-padding ion-text-center">
				{{ $t("loginModal.forgotPasswordText") }}
			</ion-item>

			<ion-item class="ion-no-padding" lines="none">
				<ion-label class="ion-text-center" position="stacked">{{ forgotPassword ? $t("loginModal.forgotEmail") : $t("loginModal.username") }}</ion-label>
				<ion-input class="ion-margin-bottom ion-text-center" v-model="form.username" name="username" type="text" inputmode="email" autocapitalize="off"></ion-input>
			</ion-item>

			<ion-item v-if="!forgotPassword" class="ion-no-padding" lines="none">
				<ion-label class="ion-text-center" position="stacked">{{ $t("loginModal.password") }}</ion-label>
				<ion-input class="ion-margin-bottom ion-text-center" v-model="form.password" @keyup.enter="submitLoginForm()" name="password" type="password"></ion-input>
			</ion-item>

			<ion-button :disabled="submitDisabled" type="submit" class="ion-margin ion-text-center" color="dark" shape="round" expand="block">
				<ion-icon class="ion-margin-end" :icon="enterOutline" />
				{{ forgotPassword ? $t("loginModal.send") : $t('login.login') }}
			</ion-button>
			<ion-spinner v-if="submitDisabled === true" class="ion-margin"></ion-spinner>
		</form>

		<!-- Password reset success message -->
		<transition name="fade">
			<div v-if="forgotPasswordSuccess === true && forgotPassword === true" class="ion-margin">
				<h3 class="ion-margin-top">{{ $t("errors.loginError.forgetTitle") }}</h3>
				{{ $t("errors.loginForgotSuccess") }}
			</div>
		</transition>

		<ion-button fill="clear" @click="forgotPassword = !forgotPassword">
			{{ forgotPassword ? $t("loginModal.back") : $t("loginModal.forgotPassword") }}
		</ion-button>
	</div>
</template>

<script>
import LoginMixin from './LoginMixin.js'
import { enterOutline, lockClosed } from "ionicons/icons";
import WiseSettings from '@/WiseSettings.js'

export default {
	name: "WiseLoginForm",
	emits: ["loginComplete", "toggle-password-reset-title"],
	mixins: [ LoginMixin ],
	props: {
		urlToOnSuccess: {
			default: false // Set in router when not logged in and accessing guarded route, after succesful login user is redirected this route
		},
		accessTokenUnvalid: {
			default: false // Set in router when logged in but accessToken is not valid
		}
	},
	data: () => ({
		loginModalHostText: WiseSettings.loginModalHostText || 'loginModal.gym',
		loginModalSelectText: WiseSettings.loginModalSelectText || 'loginModal.chooseGym',
		forgotPassword: false,
		forgotPasswordSuccess: false,
		submitDisabled: false,
		form: {
			username: "",
			password: "",
		},
		enterOutline, lockClosed
	}),
	watch: {
		forgotPassword() {
			this.$emit('toggle-password-reset-title', this.forgotPassword) // Toggle modal title (reset / login)
		}
	},
	async mounted() {
		// If only one host in settings set this host to selected host
		if(this.settings.hosts.length === 1) this.mutateSelectedHost(this.settings.hosts[0].name)
	},
};
</script>

<style scoped>
	/* Vue transitions classes */
  .fade-enter-from {
    opacity: 0;
  }
  .fade-enter-to {
    opacity: 1;
  }
  .fade-enter-active {
    transition: all 2s ease;
  }
  .fade-leave-from {
    opacity: 1;
		transform: scale(1);
  }
  .fade-leave-to {
    opacity: 0;
  }
  .fade-leave-active {
    transition: all 0s ease;
  }
</style>