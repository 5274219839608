
<template>
    <ion-page>
        <slot name="header">
            
        </slot>
        <ion-content class="ion-padding">
            <div class="wise-page-root">
                <div class="wise-page-spacer"></div>
                <div>
                    <slot></slot>
                </div>
            </div>
        </ion-content>
    </ion-page>
    </template>
    
    <style scoped>
    .wise-page-root {
        display: flex;
        flex-direction: column;
        min-height: calc(100% - var(--ion-padding, 16px));
    }
    
    .wise-page-spacer {
        flex-grow: 1;
    }
    </style>