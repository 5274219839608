<template>
    <ion-button class="circle back-button" color="secondary"
        v-if="hasOverrideFuntion || routerStackIsNotEmpty" 
        @click="action">
        <i class="wn-icon arrow-left"></i>
    </ion-button>
</template>

<script>
export default {
    props: {
        onClick: {
            type: Function,
            required: false,
            default: null,
        },
    },
    name: 'WiseBackButton',
    computed: {
        hasOverrideFuntion() {
            return typeof this.onClick === 'function'
        },
        routerStackIsNotEmpty() {
            return window.history.length > 1 ? true : false;
        },
    },
    methods: {
        action() {
            if (this.hasOverrideFuntion) {
                this.onClick()
            } else if (this.routerStackIsNotEmpty) {
                this.$router.back()
            }
        },
    },
}
</script>