// WiseGym vuex modules
import gym_common from "@/WiseGym/GymStore/modules/gym_common"

// WiseGym components
import GymCategoryList from "@/WiseGym/components/GymCategoryList.vue";
import GymCalendar from '@/WiseGym/components/GymCalendar'

// WiseGym styles
import '@/WiseGym/wisegym.css';

import {
	IonSegment,
	IonSegmentButton,
} from '@ionic/vue';

const components = {
	IonSegment,
	IonSegmentButton,
	GymCategoryList,
	GymCalendar
}

export default {
	install(app) {
		Object.keys(components).forEach((name) => {
			const component = components[name];
			app.component(name, component);
		});

		if (typeof app.config.globalProperties.$store !== 'undefined') {
			app.config.globalProperties.$store.registerModule('gym_common', gym_common)
		}
	}
};
