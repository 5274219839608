import store from '../store';
//import { modalController } from "@ionic/vue";
//import LoginModal from "@/WiseCore/components/WiseLoginModal.vue";

export async function defaultGuard(to, from, next) {
    try {
        // User is logged in, validate accessToken
        const accessTokenValid = await store.dispatch("user/validateAccessTokenFromStorage")

        if (accessTokenValid === false) {
            console.log(`%cAccess token unvalid, user need to re-login`, 'color: yellow; padding: 5px 0')
            // reset all states before trying to log back in
            store.dispatch('user/logOut')
            next('/');
            return
        }
        // All good, user can access route
        next()
    } catch (error) {
        console.error("Router Guard error: ", error)
        store.dispatch('user/logOut')
        next('/');
    }
}
/*
// just redirect to start?
async function openLoginModal(pathTo, accessTokenUnvalid = false) {
    // reset all states before trying to log back in
    store.dispatch('user/logOut')

    const modal = await modalController.create({
        component: LoginModal,
        cssClass: 'wise-login-modal',
        componentProps: {
            urlToOnSuccess: pathTo,
            accessTokenUnvalid
        },
    });
    return modal.present();
}
*/