const stateFactory = () => ({
    activeMembershipStamps: {},
    overallStampUsage: 0
})

export default {
    namespaced: true,
    state: stateFactory(),
    getters: {
        activeMembershipStamps: (state) => state.activeMembershipStamps,
        overallStampUsage: (state) => state.overallStampUsage,
        // kymppikortti case
        membershipStamps(state, getters, rootState, rootGetters) {
            const resourceRules = rootGetters['res_reservations/resourceRules'];
            const rules = resourceRules.filter(row => row.ruleName === 'kymppikortti' && row.isUserRule);

            return rules;
        },
        membershipStampsQuantity(state, getters, rootState, rootGetters) {
            const { quantity } = rootGetters['res_calendar/activeReservation'];
            const memberships = {};

            if (!getters.membershipStamps) return memberships;
            
            Object.keys(state.activeMembershipStamps)
            .forEach(key => {
                const value = state.activeMembershipStamps[key];
                const stamp = getters.membershipStamps.filter(row => row.categoryId == key)[0];

                if (!value || !stamp) return;

                const { name, usableQuantity, ruleValue } = stamp;
                let usageMultiplier = 1;
                // If the resource rule that connects the membership, has a ruleValue object
                // and a usage multiplier, use that. Defaults to 1.
                // For example 2x for 18 holes of golf and 1x too 9 holes of golf
                if (ruleValue && typeof ruleValue.usageMultiplier === 'number') {
                    usageMultiplier = ruleValue.usageMultiplier;
                }

                let stampQuantity = quantity * usageMultiplier;
                let valid = true;

                // this was decided that if a stamp is used, it has to have enough quantity
                // for the full reservation (18.10.2021)
                if (usableQuantity < quantity * usageMultiplier) {
                    stampQuantity = usableQuantity;
                    valid = false;
                }

                memberships[key] = {
                    name,
                    usableQuantity,
                    quantity: stampQuantity,
                    usageMultiplier,
                    valid,
                }
            })

            return memberships;
        },
    },
    mutations: {
        setStampUsage(state, { categoryId, value }) {
            state.activeMembershipStamps[categoryId] = value;
            /*
            // Vue.set removed from version 3, hopefully this works...
            Vue.set(state.activeMembershipStamps, categoryId, value);
            Vue.set(state.activeMembershipStamps, categoryId, quantity);
            
            state.overallStampUsage = Object.keys(state.activeMembershipStamps)
                .map(key => state.activeMembershipStamps[key])
                .filter(Boolean)
                .reduce((prev, curr) => prev + curr, 0)
            */
        },
        reset(state) {
            const defaultState = stateFactory();

            Object.keys(defaultState)
            .forEach(name => {
                const value = defaultState[name];
                
                state[name] = value;
            })
        }
    },
    actions: {
        toggleStampUsage({ state, commit }, { categoryId }) {
            let value = true;

            if (state.activeMembershipStamps[categoryId]) {
                value = false;
            }

            commit('setStampUsage', { categoryId, value });
        },
        init({ state, getters, commit }) {
            commit('reset');

            const memberships = getters.membershipStamps;
            let preselectedCategoryId = null;
            
            memberships.forEach(row => {
                const { ruleValue, categoryId, usableQuantity } = row;
                const { usageMultiplier, forceUsage } = ruleValue;
                const available = parseInt(usableQuantity) >= usageMultiplier;
                state.activeMembershipStamps[categoryId] = undefined;
                //Vue.set(state.activeMembershipStamps, row.categoryId, undefined);
                if (!preselectedCategoryId && forceUsage && available) {
                    preselectedCategoryId = categoryId;
                }
            })

            return Promise.resolve({
                preselectedCategoryId,
            })
        },
    }
}