import { alertController, toastController } from "@ionic/vue";
import { mapMutations } from "vuex";

export default {
	methods: {
		...mapMutations({mutateCommonShowAlert: "common/mutateCommonShowAlert"}),

		async showAlert(title = false, message) {
			const alert = await alertController.create({
				header: title ? title : "",
				message: message,
				backdropDismiss: false,
				buttons: ["Ok"]
			});

			this.mutateCommonShowAlert(alert) // Set alert instance to vuex to use onDidDismiss etc... from components/views
			return alert.present();
		},
		async showToast(title = false, body, color = "primary") {
			const toast = await toastController.create({
				header: title ? title : false,
				message: body,
				duration: 7500,
				position: "top",
				color,
				buttons: [
					{
						text: this.$t("close"),
						role: "cancel",
					}
				]
			})
			await toast.present();
		}
	},
};