<template>
		<!-- If no events -->
		<div v-if="categories === true && filterEventsByDate(events)?.length < 1 || categories === false && filterEventsByDate(events)?.length < 1">
            <PlaceHolder icon="zzz-line" :title="$t('wiseFitness.noEvents')" />
		</div>

		<ion-item class="calendar-event" lines="none" v-for="event in processedEvents ? filterEventsByDate(events) : filterEventsByDate(events)" :key="event.activityId" @click="() => $router.push(`fitness/event/${event.activityId}`)"
			:class="event.item">
			<ion-grid>
				<ion-row class="ion-align-items-center">
					<ion-col class="calendar-header"> <span v-if="activeHost.useEventCategoryNamePill" class="wn-pill calendar"> {{ event.eventCategoryName }}</span>{{ event.name }}</ion-col>
					<ion-col v-if="!(event.totalCapacity < 1)" class="calendar-capacity ion-text-center"> {{ event.totalAttendeeQuantity }}<span v-if="event.totalQueueQuantity > 0"> ({{ event.totalQueueQuantity }})</span>/{{ event.totalCapacity }}</ion-col>
				</ion-row>
				<ion-row class="calendar-subtext">
					<ion-col  class="calendar-event-details">
						<strong v-if="event.address !== '' "> {{ event.address + ' ' }}</strong><span>{{ event.venue }}</span>
						<span v-if="(event.address || event.venue) && event.activityDisplayPersons">{{ ', ' }} </span>
						<span v-if="event.activityDisplayPersons"> {{event.activityDisplayPersons}} </span>
						<span class="calendar-event-date-time">{{ $filters.upperCaseFirst($filters.eventFormatDate(event)) }}</span>
					</ion-col>
					<ion-col class="ion-text-right calendar-event-button-area">
						<ion-button
							@click="handleEvent($event, event.action, event)"
							v-if="event.action !== 'hide'"
							:disabled="event.action === 'disabled'"
							:class="event.class" class="circle">
							<i slot="icon-only" class="s-400 wn-icon calendar-button" :class="event.icon"></i>
						</ion-button>
					</ion-col>
				</ion-row>	
			</ion-grid>
		</ion-item>
</template>

<script>

import { mapState, mapGetters } from "vuex";
import moment from 'moment';
import commonGymFunctions from '@/helpers/commonGymFunctions'

export default {
	name: "GymCategoryList",
	mixins: [commonGymFunctions],
	props: {
		events: Object,
		categories: Boolean
	},
	computed: {
		...mapGetters({
			activeHost: "common/activeHost",
		}),
		...mapState({
			selectedDate: state => state.gym_common.selectedDate,
			ownEvents: state => state.gym_common.ownEvents,
			user: state => state.user.user,
		}),

		processedEvents() {
			this.events?.forEach(event => {
				this.getButtonAction(event);
			});
			return this.events;
    }
},
	methods: {

		filterEventsByDate(events) {
			if(this.selectedDate === null) {
				
				if(this.ownEvents === true) {
					return events?.filter(event => event.eventAttendees?.find(attendee => attendee?.personId === this.user?.personId))
				}
				return events
			}
			return events?.filter(event => moment(event?.activityStartTime, 'DD.MM.YYYY HH:mm').format('DD.MM.YYYY') === this.$filters.dateShort(this.selectedDate))
		}
	}
};
</script>