const sortCountryCodes = (a, b) => a.country > b.country ? 1 : -1;

export const countryCodes = [
    {
        "name": "Germany",
        "country": "DE",
        "continent": "Europe",
        "code": "+49",
        "lang": "DE"
    },
    {
        "name": "United Kingdom",
        "country": "GB",
        "continent": "Europe",
        "code": "+44",
        "lang": "EN"
    },
    {
        "name": "France",
        "country": "FR",
        "continent": "Europe",
        "code": "+33",
        "lang": "FR"
    },
    {
        "name": "Italy",
        "country": "IT",
        "continent": "Europe",
        "code": "+39",
        "lang": "IT"
    },
    {
        "name": "Russia",
        "country": "RU",
        "continent": "Europe",
        "code": "+7",
        "lang": "RU"
    },
    {
        "name": "Spain",
        "country": "ES",
        "continent": "Europe",
        "code": "+34",
        "lang": "ES"
    },
    {
        "name": "Netherlands",
        "country": "NL",
        "continent": "Europe",
        "code": "+31",
        "lang": "NL"
    },
    {
        "name": "Switzerland",
        "country": "CH",
        "continent": "Europe",
        "code": "+41",
        "lang": "DE"
    },
    {
        "name": "Poland",
        "country": "PL",
        "continent": "Europe",
        "code": "+48",
        "lang": "PL"
    },
    {
        "name": "Sweden",
        "country": "SE",
        "continent": "Europe",
        "code": "+46",
        "lang": "SV"
    },
    {
        "name": "Belgium",
        "country": "BE",
        "continent": "Europe",
        "code": "+32",
        "lang": "NL"
    },
    {
        "name": "Norway",
        "country": "NO",
        "continent": "Europe",
        "code": "+47",
        "lang": "NO"
    },
    {
        "name": "Austria",
        "country": "AT",
        "continent": "Europe",
        "code": "+43",
        "lang": "DE"
    },
    {
        "name": "Ireland",
        "country": "IE",
        "continent": "Europe",
        "code": "+353",
        "lang": "EN"
    },
    {
        "name": "Denmark",
        "country": "DK",
        "continent": "Europe",
        "code": "+45",
        "lang": "DA"
    },
    {
        "name": "Finland",
        "country": "FI",
        "continent": "Europe",
        "code": "+358",
        "lang": "FI"
    },
    {
        "name": "Czech Republic",
        "country": "CZ",
        "continent": "Europe",
        "code": "+420",
        "lang": "CS"
    },
    {
        "name": "Greece",
        "country": "GR",
        "continent": "Europe",
        "code": "+30",
        "lang": "EL"
    },
    {
        "name": "Portugal",
        "country": "PT",
        "continent": "Europe",
        "code": "+351",
        "lang": "PT"
    },
    {
        "name": "Romania",
        "country": "RO",
        "continent": "Europe",
        "code": "+40",
        "lang": "RO"
    },
    {
        "name": "Hungary",
        "country": "HU",
        "continent": "Europe",
        "code": "+36",
        "lang": "HU"
    },
    {
        "name": "Slovakia",
        "country": "SK",
        "continent": "Europe",
        "code": "+421",
        "lang": "SK"
    },
    {
        "name": "Bulgaria",
        "country": "BG",
        "continent": "Europe",
        "code": "+359",
        "lang": "BG"
    },
    {
        "name": "Croatia",
        "country": "HR",
        "continent": "Europe",
        "code": "+385",
        "lang": "HR"
    },
    {
        "name": "Lithuania",
        "country": "LT",
        "continent": "Europe",
        "code": "+370",
        "lang": "LT"
    },
    {
        "name": "Slovenia",
        "country": "SI",
        "continent": "Europe",
        "code": "+386",
        "lang": "SL"
    },
    {
        "name": "Latvia",
        "country": "LV",
        "continent": "Europe",
        "code": "+371",
        "lang": "LV"
    },
    {
        "name": "Estonia",
        "country": "EE",
        "continent": "Europe",
        "code": "+372",
        "lang": "ET"
    },
    {
        "name": "Cyprus",
        "country": "CY",
        "continent": "Europe",
        "code": "+357",
        "lang": "EL"
    },
    {
        "name": "Luxembourg",
        "country": "LU",
        "continent": "Europe",
        "code": "+352",
        "lang": "FR"
    },
    {
        "name": "Malta",
        "country": "MT",
        "continent": "Europe",
        "code": "+356",
        "lang": "MT"
    },
    {
        "name": "United States",
        "country": "US",
        "continent": "North America",
        "code": "+1",
        "lang": "EN"
    },
    {
        "name": "China",
        "country": "CN",
        "continent": "Asia",
        "code": "+86",
        "lang": "ZH"
    },
    {
        "name": "Japan",
        "country": "JP",
        "continent": "Asia",
        "code": "+81",
        "lang": "JA"
    },
    {
        "name": "India",
        "country": "IN",
        "continent": "Asia",
        "code": "+91",
        "lang": "HI"
    },
    {
        "name": "Brazil",
        "country": "BR",
        "continent": "South America",
        "code": "+55",
        "lang": "PT"
    },
    {
        "name": "Russia",
        "country": "RU",
        "continent": "Asia",
        "code": "+7",
        "lang": "RU"
    },
    {
        "name": "Indonesia",
        "country": "ID",
        "continent": "Asia",
        "code": "+62",
        "lang": "ID"
    },
    {
        "name": "Mexico",
        "country": "MX",
        "continent": "North America",
        "code": "+52",
        "lang": "ES"
    },
    {
        "name": "South Korea",
        "country": "KR",
        "continent": "Asia",
        "code": "+82",
        "lang": "KO"
    },
    {
        "name": "Australia",
        "country": "AU",
        "continent": "Oceania",
        "code": "+61",
        "lang": "EN"
    },
    {
        "name": "Canada",
        "country": "CA",
        "continent": "North America",
        "code": "+1",
        "lang": "EN"
    },
    {
        "name": "Saudi Arabia",
        "country": "SA",
        "continent": "Asia",
        "code": "+966",
        "lang": "AR"
    },
    {
        "name": "Turkey",
        "country": "TR",
        "continent": "Asia",
        "code": "+90",
        "lang": "TR"
    },
    {
        "name": "Iran",
        "country": "IR",
        "continent": "Asia",
        "code": "+98",
        "lang": "FA"
    },
    {
        "name": "Nigeria",
        "country": "NG",
        "continent": "Africa",
        "code": "+234",
        "lang": "EN"
    },
    {
        "name": "Argentina",
        "country": "AR",
        "continent": "South America",
        "code": "+54",
        "lang": "ES"
    },
    {
        "name": "South Africa",
        "country": "ZA",
        "continent": "Africa",
        "code": "+27",
        "lang": "EN"
    },
    {
        "name": "Pakistan",
        "country": "PK",
        "continent": "Asia",
        "code": "+92",
        "lang": "UR"
    },
    {
        "name": "Colombia",
        "country": "CO",
        "continent": "South America",
        "code": "+57",
        "lang": "ES"
    },
    {
        "name": "Egypt",
        "country": "EG",
        "continent": "Africa",
        "code": "+20",
        "lang": "AR"
    }
].sort(sortCountryCodes)