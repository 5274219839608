<template>
<div class="wn-dropdown" ref="master">
    <div class="wn-dropdown__button" 
        ref="action"
        @click="toggleShow($event)">
        <slot name="button"></slot>
    </div>
    <div class="wn-dropdown__base" 
        ref="base"
        v-show="show"
        @click="onSelect">
        <slot name="options"></slot>
    </div>
</div>
</template>

<script>
// This component is derived from: https://github.com/opiispanen/Viixet/blob/master/src/Viixet/components/DropDown.vue
export default {
    emits: ['select'],
    props: {
        sideOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        show: false
    }),
    methods: {
        onSelect(evt) {
            const { base } = this.$refs
            
            if (base.contains(evt.target)) {
                this.show = false;
                this.$emit('select', evt.target)
            }
        },
        toggleShow(evt) {
            const { action } = this.$refs
            
            if (action.contains(evt.target)) {
                this.show = !this.show;
            }

            if (this.show) {
                this.adjustBasePosition();
            }
        },
        adjustBasePosition() {
            const { base, action } = this.$refs
            const bounding = action.getBoundingClientRect();
            const distances = {
                toLeft: bounding.x,
                toRight: window.innerWidth - (bounding.x + bounding.width),
                toTop: bounding.y,
                toBottom: window.innerHeight - (bounding.y + bounding.height) 
            }
            const gap = 2;
            const height = this.sideOpen ? 0 : bounding.height + gap;
            const width = bounding.width + gap;
            
            if (distances.toTop <= distances.toBottom) {
                base.style.top = `${ height }px`;
            } else {
                base.style.bottom = `${ height }px`;
            }

            if (this.sideOpen) {
                if (distances.toLeft <= distances.toRight) {
                    base.style.left = `${ width }px`;
                } else {
                    base.style.right = `${ width }px`;
                }
            }
        },
        handleClickOutside(evt) {
            const { master } = this.$refs

            if (!master.contains(evt.target)) {
                this.show = false;
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside)
    },
    beforeUnmount() {
        // Remove click event listener when the component is unmounted
        document.removeEventListener('click', this.handleClickOutside)
    },
}
</script>