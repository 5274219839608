<template>
	<div>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-title>{{ forgotPassword ? $t("login.passwordResetTitle") : $t("login.login") }}</ion-title>
				<ion-buttons slot="end">
					<ion-button fill="clear" @click="closeModal()">
						{{ $t('close') }}
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding ion-text-center">
			<LoginForm v-if="loggedIn === false"
				:urlToOnSuccess="urlToOnSuccess" 
				:accessTokenUnvalid="accessTokenUnvalid" 
				@loginComplete="closeModal" 
				@toggle-password-reset-title="passwordResetTitleToggle" 
				/>
			<div class="center" v-if="loggedIn === true">
				<div>
					<h3>{{ $t("wiseLoginModal.alreadyLogged") }}</h3>
					<a @click="closeModal()">{{ $t("wiseLoginModal.goBack") }}</a>
				</div>
			</div>
		</ion-content>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { modalController } from "@ionic/vue";

export default {
	name: "WiseLoginModal",
	props: {
		urlToOnSuccess: {
			default: false // Set in router when not logged in and accessing guarded route, after succesful login user is redirected this route
		},
		accessTokenUnvalid: {
			default: false // Set in router when logged in but accessToken not valid
		}
	},
	data() {
		return {
			forgotPassword: false,
			loading: null,
		};
	},
	computed: {
		// ...mapState(["loggedIn"])
		...mapState({
			loggedIn: state => state.user.loggedIn,
		}),
	},
	methods: {
		// Emits from WiseLoginForm
		passwordResetTitleToggle(forgotPassword) {
			this.forgotPassword = forgotPassword
		},
		closeModal() {
			modalController.dismiss();
		},
	},
};
</script>
