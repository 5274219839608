// TODO moment global import, vue-moment not ready for Vue3 https://github.com/BrockReece/vu3-moment
import moment from 'moment';
import settings from '@/WiseSettings.js';

export function setupFilters(app, i18n) {

	app.config.globalProperties.$filters = {
		upperCaseFirst(string) {
			return string.charAt(0).toUpperCase() + string.slice(1)
		},
		eventFormatDate(event, startOnly = false) {
			moment.locale(i18n.global.locale.value)
			const activityStartMoment = moment(event.activityStartTime, 'DD.MM.YYYY HH:mm');
			const activityEndMoment = moment(event.activityEndTime, 'DD.MM.YYYY HH:mm');
			const returnString = `${activityStartMoment.format('ddd DD.MM.YYYY')} ${i18n.global.t("wiseFitness.at")} ${activityStartMoment.format('HH:mm')}`
			return startOnly ? returnString : (returnString + ' - ' + activityEndMoment.format('HH:mm'));
		},
		dateLong(date) {
			moment.locale(i18n.global.locale.value)
			return moment(date).format('dddd DD.MM.YYYY')
		},
		dateShortDayMonth(date) {
			moment.locale(i18n.global.locale.value)
			return moment(date).format('DD.MM')
		},
		dateShort(date) {
			moment.locale(i18n.global.locale.value)
			return moment(date).format('DD.MM.YYYY')
		},
		dateNowFull() {
			return moment().format('YYYY-MM-DD HH:mm:ss')
		},
		yearsDiff(date) {
			return moment().diff(date, 'years');
		},
		minAgeToRegister() {
			if (typeof settings.minAgeToRegister !== "number") {
				console.warn("settings: minAgeToRegister is not number or missing")
			}
			return moment().subtract(settings.minAgeToRegister, 'years').format('YYYY-MM-DD')
		},
		adultAge() {
			return moment().subtract(18, 'years').format('YYYY-MM-DD')
		},
		checkIsAfterNow(dateTimeStart) {
			return moment(dateTimeStart, 'DD.MM.YYYY HH:mm').utcOffset(0, true).isAfter(moment().utcOffset(0, true), 'minute')
		},
		checkIsDateAfter(start, end) {
			return moment(start).isAfter(end)
		}
	}
}