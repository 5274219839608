import { ref } from 'vue'
import axios from 'axios';
import wisestore from '@/store';
import WiseSettings from '@/WiseSettings.js'

export const GolfState = {
    clubChangeInProgress: ref(false),
    clubChangeTimeStamp: ref(0),
    showDefaultTabButton: ref(true),
    showTabs: ref(true),
    selectedClub: ref(null),
    associatedClubs: ref(
        WiseSettings.hosts.filter(row => row.golfClubId)
        .map(row => ({
            ...row,
            urls: {
                baseUrl: row.baseUrl,
                ajaxUrl: row.ajaxUrl,
                restUrl: row.restUrl,
                ecomUrl: row.ecomUrl,
            }
        }))
    ),
    rangeDispencerAvailable: ref(false),
}

export const setClubChangeProgress = (value) => GolfState.clubChangeInProgress.value = value;
export const setClubChangeTimeStamp = (value) => GolfState.clubChangeTimeStamp.value = value;
export const setShowTabs = (value) => GolfState.showTabs.value = value;
export const setShowDefaultTabButton = (value) => GolfState.showDefaultTabButton.value = value;
export const setSelectedClub = (value) => GolfState.selectedClub.value = value;
export const setAssociatedClubs = (value) => GolfState.associatedClubs.value = value;
export const setRangeDispencerAvailable = (value) => GolfState.rangeDispencerAvailable.value = value;

export async function getAssociatedClubs(settings) {
    try {
        return await axios({
            url: `${ settings.restUrl }/golf/club/associated/`,
            method: 'GET',
            withCredentials: true,
        })
    } catch (error) {
        console.error(error)
    }

    return [];
}

export async function getUserGolfReservations(settings) {
    try {
        return await axios({
            method: 'GET',
            url: `${ settings.ajaxUrl }?reservations=getusergolfreservations&appauth=${ settings.appauth }`,
        })
    } catch (error) {
		console.error(error)
	}

    return [];
}

export function resolveSelectedClubSettings() {
    const { selectedClub, associatedClubs } = GolfState;
    const selectedClubName = selectedClub.value || associatedClubs.value[0].name;
    const club = associatedClubs.value.filter(row => row.name === selectedClubName)[0];
    const { appauth, urls, articleCategory } = club;
    const { ajaxUrl } = urls;
    const settings = {
        ajaxUrl,
        appauth,
    }
    
    if (articleCategory) {
        settings.categoryName = articleCategory;
    }
    
    return settings;
}

export async function loadRangeDispencer() {
    try {
        const EcomService = wisestore.getters['res_ecom/EcomService']
        const { data } = await EcomService.initRangeDispencer();
        const { success, devices } = data;
    
        if (success && Array.isArray(devices) && devices.length > 0) {
            setRangeDispencerAvailable(true)
        } else {
            setRangeDispencerAvailable(false)
        }
    } catch(e) {
        console.warn('loadRangeDispencer', e)
    }
}