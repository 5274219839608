import moment from 'moment'
const VIEW_MODES = {
    calendar: 0,
    rows: 1,
    grid: 2
};
/*
let defaultViewMode = VIEW_MODES.calendar;

if (!!localStorage['resevationViewMode']) {
    try {
        defaultViewMode = JSON.parse(localStorage['resevationViewMode']);
    } catch(e) {}
}
*/

const stateFactory = () => ({
    selectedViewMode: VIEW_MODES.calendar,
    timeTable: [],
    baseTimeTable: [],
    activeReservation: null,
    editReservation: null,
    availableAdditionalResources: [],
    // numbered resource products are formatted in resourceCategory like golfcart-14
    // <something>-<number>
    numberedResourceProducts: [],
    additionalResourceQuantity: {},
    additionalNumberedResourceQuantity: {},
    websocketReservations: [],
})

export default {
    namespaced: true,
    state: stateFactory(),
    getters: {
        selectedViewMode: (state) => state.selectedViewMode,
        VIEW_MODES: () => VIEW_MODES,
        timeTable: (state, getters, rootState, rootGetters) => {
            const TIMEBLOCK_TYPE = rootGetters['res_reservations/TIMEBLOCK_TYPE'];
            // all calendar types
            const CALENDAR_TYPE = rootGetters['res_reservations/CALENDAR_TYPE'];
            // selected calendar types
            const calendarType = rootGetters['res_reservations/calendarType'];
            
            // handle websocket reservations here
            // go through reservations and find a block for them
            // use blockId?
            // blockId: `${ resource.resourceId }-${ delta.format('X') }`,
            const websocketReservations = state.websocketReservations;
            
            if (websocketReservations.length > 0 && calendarType !== CALENDAR_TYPE.weekCalendar) {
                return state.timeTable.map(table => {
                    const reservations = websocketReservations.filter(row => row.resourceId == table.resourceId)

                    reservations.forEach(row => {
                        const block = table.timeBlocks.filter(block => block.blockId == row.blockId)[0]

                        if (block) {
                            block.originalType = block.type;
                            block.type = TIMEBLOCK_TYPE.websocketReservation;
                            // block.info ?
                        }
                    })

                    return table;
                });
            }

            return state.timeTable;
        },
        baseTimeTable: (state) => state.baseTimeTable,
        reviewBlock: (state, getters, rootState, rootGetters) => (data) => {
            const { index, table } = data;
            const block = table.timeBlocks[index];
            const TIMEBLOCK_TYPE = rootGetters['res_reservations/TIMEBLOCK_TYPE'];
        
            let disallowedTypes = [
                TIMEBLOCK_TYPE.disabled, 
                TIMEBLOCK_TYPE.reservation, 
                TIMEBLOCK_TYPE.startTooClose, 
                TIMEBLOCK_TYPE.endTooClose,
                TIMEBLOCK_TYPE.reservationFull,
                TIMEBLOCK_TYPE.reservationInCart,
                TIMEBLOCK_TYPE.disallowedWithinTime,
                TIMEBLOCK_TYPE.disabledByRule,
                TIMEBLOCK_TYPE.startDisabled,
                TIMEBLOCK_TYPE.websocketReservation,
                TIMEBLOCK_TYPE.resellableReservation,
            ];

            if (rootGetters['res_common/adminMode']) {
                disallowedTypes = [
                    TIMEBLOCK_TYPE.disabled, 
                    TIMEBLOCK_TYPE.reservation,
                    TIMEBLOCK_TYPE.reservationFull,
                    TIMEBLOCK_TYPE.reservationInCart,
                    TIMEBLOCK_TYPE.disabledByRule,
                    TIMEBLOCK_TYPE.websocketReservation,
                    TIMEBLOCK_TYPE.resellableReservation,
                ];
            }

            if (disallowedTypes.indexOf(block.type) > -1) {
                return false;
            }

            if (!rootGetters['res_common/adminMode'] && block.past) {
                return false;
            }

            return true;
        },
        availableBlocks: (state) => state.activeReservation.availableBlocks.length,
        selectedBlockIndex: (state) => state.activeReservation.selectedIndex,
        activeReservation: (state) => {
            if (!state.activeReservation) {
                return {
                    placeholder: true,
                    reservations: [],
                    recurringDates: [],
                    start: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
                    end: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 0 }),
                    resourceId: null,
                }
            }

            return state.activeReservation
        },
        editReservation: (state) => state.editReservation || {},
        availableAdditionalResources: (state) => state.availableAdditionalResources,
        numberedResourceProducts: (state) => state.numberedResourceProducts,
        additionalResourceQuantity: (state) => state.additionalResourceQuantity,
        additionalNumberedResourceQuantity: (state) => state.additionalNumberedResourceQuantity,
        selectedAdditionalResources: (state) => {
            return state.availableAdditionalResources
            .map((resource) => {
                resource.orderQuantity = state.additionalResourceQuantity[resource.resourceId] || 0;
                return resource;
            })
            .filter(
                (resource) => resource.orderQuantity > 0 && !!resource.productPrice
            )
        },
        selectedNumberedResources: (state) => {
            return state.numberedResourceProducts
            .map(product => {
                product.orderQuantity = state.additionalNumberedResourceQuantity[product.productId] || 0;

                return product;
            })                
            .filter(
                (product) => product.orderQuantity > 0 && !!product.productPrice
            )
        },
        websocketReservations: (state) => state.websocketReservations,
    },
    mutations: {
        reset(state) {
            const defaults = stateFactory();

            Object.keys(defaults).forEach(key => {
                const value = defaults[key];

                state[key] = value;
            })
        },
        setViewMode(state, mode) {
            if (typeof mode === 'string') {
                if (typeof VIEW_MODES[mode] === 'number') {
                    state.selectedViewMode = VIEW_MODES[mode];
                }
            } else {
                state.selectedViewMode = mode;
            }
        },
        setActiveReservation(state, reservation) {
            state.activeReservation = reservation;
            state.activeReservation.recurringDates = [];
        },
        setEditReservation(state, { block, reservation }) {
            state.editReservation = {
                block,
                reservation,
                timestamp: Date.now(),
            };
        },
        adjustAdditionalResourceQuantity(state, { add, row, force = false }) {
            if (add) {
                if (state.additionalResourceQuantity[row.resourceId] < row.maxQuantity 
                        && state.additionalResourceQuantity[row.resourceId] < row.availableQuantity) {
                    state.additionalResourceQuantity[row.resourceId] += 1;
                }
            } else {
                if (state.additionalResourceQuantity[row.resourceId] > 0 
                        && (state.additionalResourceQuantity[row.resourceId] > row.minQuantity || force)) {
                    state.additionalResourceQuantity[row.resourceId] -= 1;
                }
            }
        },
        adjustNumberedResourceQuantity(state, { add, row }) {
            if (add) {
                if (state.additionalNumberedResourceQuantity[row.productId] < row.maxQuantity 
                        && state.additionalNumberedResourceQuantity[row.productId] < row.availableQuantity) {
                    state.additionalNumberedResourceQuantity[row.productId] += 1;
                }
            } else {
                if (state.additionalNumberedResourceQuantity[row.productId] > 0 
                        && (state.additionalNumberedResourceQuantity[row.productId] > row.minQuantity)) {
                    state.additionalNumberedResourceQuantity[row.productId] -= 1;
                }
            }
        },
        setAdditionalResourceQuantity(state, { amount, row, force = false }) {
            const max = amount <= row.maxQuantity;
            const available = amount <= row.availableQuantity;
            const min = amount >= row.minQuantity;

            if ((max && available && min) || force) {
                state.additionalResourceQuantity[row.resourceId] = amount;
            }
        },
        // when connection is established, product reservations are sent as a whole
        setWebsocketReservations(state, rows) {
            state.websocketReservations = rows;
        },
        addWebsocketReservation(state, row) {
            state.websocketReservations.push(row);
        },
        removeWebsocketReservation(state, id) {
            const index = state.websocketReservations.findIndex(row => row.websocketReservationId == id);
            const [ reservation ] = state.websocketReservations.splice(index, 1);

            const { timeBlocks } = state.timeTable.filter(row => row.resourceId === reservation.resourceId)[0]
            
            if (timeBlocks) {
                const [ block ] = timeBlocks.filter(row => row.blockId === reservation.blockId);
                
                if (block && block.type === 99) {
                    block.type = block.originalType;
                    delete block.originalType;
                }
            }
        },
    },
    actions: {
        setEditReservation({ state, commit }, { block, reservation, cacheActiveReservation }) {
            if (!cacheActiveReservation) {
                state.activeReservation = null;
            }
            commit('setEditReservation', { block, reservation })
            
            return Promise.resolve();
        },
        initTimeTables({ state, rootGetters }, data) {
            const { timeTable, baseTimeTable } = data;
            const CALENDAR_TYPE = rootGetters['res_reservations/CALENDAR_TYPE'];
            const settings = rootGetters['res_reservations/settings'];

            if ([CALENDAR_TYPE.weekCalendar,CALENDAR_TYPE.resource].includes(settings.type)) {
                if (typeof timeTable !== 'undefined') {
                    state.timeTable = timeTable;
                }
                
                if (typeof baseTimeTable !== 'undefined') {
                    state.baseTimeTable = baseTimeTable;
                }
            } else {
                if (Array.isArray(timeTable) && timeTable.length > 0) {
                    state.timeTable = timeTable;
                }
                if (Array.isArray(baseTimeTable) && baseTimeTable.length > 0) {
                    state.baseTimeTable = baseTimeTable;
                }
            }
            //console.log('initTimeTables', data)
            return Promise.resolve();
        },
        // IDEA: to avoid UI jumping in confirmation modals, have a loading state for resources 
        // and a placeholder in the UI meanwhile loading
        updateAvailableAdditionalResources({ state, rootGetters }, firstLoad = false) {
            const EcomService = rootGetters['res_ecom/EcomService'];
            const additionalResources = rootGetters['res_reservations/additionalResources'];
            const { groupResourcesAsProducts } = rootGetters['res_reservations/settings'];
            
            return EcomService.getResourceReservations(
                additionalResources.map(row => row.resourceId),
                state.activeReservation.start.format('YYYY-MM-DD HH:mm'),
                state.activeReservation.end.format('YYYY-MM-DD HH:mm')
            )
            .then((response) => {
                const data = response.data;

                if (data.success === false) { 
                    return;
                }
                
                const resources = additionalResources.map(resource => {
                    const reservations = data.resources[resource.resourceId];

                    resource.reservedQuantity = reservations ? reservations.quantity : 0;
                    resource.availableQuantity = resource.quantity - resource.reservedQuantity;

                    if (firstLoad) {
                        let setDefaultQuantity = true;
                        
                        if (groupResourcesAsProducts) {
                            setDefaultQuantity = false;
                        }

                        if (setDefaultQuantity) {
                            if (resource.minQuantity < resource.availableQuantity) {
                                resource.orderQuantity = resource.minQuantity;
                            } else {
                                resource.orderQuantity = resource.availableQuantity;
                            }
                        } else {
                            resource.orderQuantity = 0;
                        }
                    } else if (resource.orderQuantity > resource.availableQuantity) {
                        resource.orderQuantity = resource.availableQuantity;
                    }

                    return resource;
                })
                // separate resources that are special equipment calendars
                state.availableAdditionalResources = resources.filter(row => row.productReservationType != 4)
                const numberedResources = resources.filter(row => row.productReservationType === 4);
                const products = {};

                for (let resource of numberedResources) {
                    const { 
                        productReservationId,
                        productId,
                        productPrice, 
                        productName,
                        availableQuantity,
                        minQuantity,
                        maxQuantity,
                        productReservationMinDuration,
                    } = resource;
    
                    if (typeof products[productId] === 'undefined') {
                        products[productId] = {
                            productReservationId,
                            productId,
                            productPrice, 
                            productName,
                            availableQuantity,
                            maxQuantity,
                            minQuantity,
                            productReservationMinDuration,
                            resources: [
                                resource.resourceId
                            ]
                        }
                    } else {
                        products[productId].availableQuantity += resource.availableQuantity;
                        products[productId].resources.push(resource.resourceId)
                    }

                    if (typeof state.additionalNumberedResourceQuantity[productId] === 'undefined') {
                        state.additionalNumberedResourceQuantity[productId] = 0;
                    }
                }
                state.numberedResourceProducts = Object.values(products);

                if (firstLoad) {
                    state.additionalResourceQuantity = state.availableAdditionalResources.reduce((acc, curr) => {
                        acc[curr.resourceId] = curr.orderQuantity;
                        return acc;
                    }, {})
                    state.additionalNumberedResourceQuantity = state.numberedResourceProducts.reduce((acc, curr) => {
                        acc[curr.productId] = 0;
                        return acc;
                    }, {})
                }
            })
        },
    }
}