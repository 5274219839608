function toFixed(num, precision) {
    const base = 10 ** precision;
    return (Math.round(num * base) / base).toFixed(precision);
}

export function formatCurrency(price, toString = true, decimals = 2) {
    if (toString) {
        if(!price) price = '0';

        return toFixed(price, decimals).replace('.',',');
    } else {
        if (typeof price === 'string') {
            return parseFloat(price.replace(',','.').replace(/\s/g, ''))
        } else if (typeof price === 'number') {
            return price; // do nothing, just a failsafe
        } else {
            return 0;
        }
    }
}