import router from './router'
// notifications to app front page from the developer to the user
// these are visible until the user confirms/uses action 
export default {
    'default-fi': [
        {
            id: 'wisegym-20353003616', //unique ID
            color: 'primary',
            title: 'Uutta tässä versiossa',
            content: 'Teimme korjauksia ilmoitusaiheiden tilauksiin. Tarkista tilauksesi, jotta saat jatkossakin haluamasi ilmoitukset.',
            callback: () => router.push('/gym/account/notifications'),
            expired: new Date('2024-11-10 00:00:00') < new Date()
        },
    ],
}