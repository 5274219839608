<template>	
			<h1 class="font-main-header">{{ $t("register.personalInformation") }}</h1>

				<ion-grid class="form-grid">
					<ion-row>
						<ion-col>
							
							<ion-item>
								<ion-label position="stacked">{{ $t('register.lastname') }}</ion-label>
								<ion-input class="input-medium" v-model="form.familyName" required :aria-label="$t('register.lastname')" />
								<ion-note class="input-helper" v-if="formErrors.familyName || formErrors.firstName" color="danger">
									{{ formErrors.familyName }} {{ formErrors.firstName }}
								</ion-note>
							</ion-item>
						</ion-col>

						<ion-col size="5">
							<ion-item>
								<ion-label position="stacked">{{ $t('register.firstname') }}</ion-label>
								<ion-input class="input-medium" v-model="form.firstName" required :aria-label="$t('register.firstname')" />
							</ion-item>            
						</ion-col>

					</ion-row>

					<!-- <ion-row class="phone-input">
						<ion-col size="4" class="input-large">
							
								<ion-label position="stacked">{{ $t('register.phone') }}</ion-label>
								<WiseCountrySelector
								v-model="selectedCountry"
								:showPhoneCode="true" />
								<ion-note class="input-helper" v-if="formErrors.phone" color="danger">
									{{ formErrors.phone }}
								</ion-note>
							
						</ion-col>
						
						<ion-col>
							<ion-item>
								<ion-input class="input-large" v-model="form.phone" required pattern="[0-9]+" :aria-label="$t('register.phone')" />
							</ion-item> 
						</ion-col>
						

					</ion-row> -->

					<ion-row>
						<ion-col>
							<ion-item>
								<ion-label position="stacked">{{ $t('register.phone') }}</ion-label>
								<ion-input class="input-large" v-model="form.phone" required pattern="[0-9]+" :aria-label="$t('register.phone')" />
								<ion-note class="input-helper" v-if="formErrors.phone" color="danger">
									{{ formErrors.phone }}
								</ion-note>
							</ion-item>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col>
							<ion-item>
								<ion-label position="stacked">{{ $t('register.email') }}</ion-label>
								<ion-input class="input-large" v-model="form.email" required type="mail" :aria-label="$t('register.email')" />
								<ion-note class="input-helper">
									{{ $t("account.changingEmailNote")}}
								</ion-note>
								<ion-note class="input-helper" size="12" v-if="formErrors.email" color="danger">
									{{ formErrors.email }}
								</ion-note>
							</ion-item>
							
						</ion-col>


					</ion-row>
				</ion-grid>

			<template v-if="settings.usePersonAdressInformation === true">
				<ion-grid class="form-grid">
					<ion-row>
						<ion-col>
							<ion-item>
								<ion-label position="stacked"> {{ $t("register.address") }}</ion-label>
								<ion-input class="input-large" v-model="form.streetAddress" required :aria-label="$t('register.address')"></ion-input>
						</ion-item>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col size="4">
							<ion-item>
								<ion-label position="stacked">{{ $t("register.zip")}}</ion-label>
								<ion-input class="input-medium" v-model="form.postalCode" required :aria-label="$t('register.zip')"></ion-input>
							</ion-item>
						</ion-col>

						<ion-col>
							<ion-item>
								<ion-label position="stacked">{{ $t("register.city") }}</ion-label>
								<ion-input class="input-medium" v-model="form.city" required pattern="[^0-9]{1,30}" :aria-label="$t('register.city')"></ion-input>
							</ion-item>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col size="6">
								<ion-label
									position="stacked">
									{{ $t("register.birthday") }}
								</ion-label>
								<DatePicker 
									:first-day-of-week="2" 
									:is-dark="$store.getters['common/darkMode']"
									v-model="form.birthday" 
									:locale="$i18n.locale"
									:popover="{ 
										visibility: 'click',
										placement: 'top-start'}">
									<template #default="{ inputValue, inputEvents }">
										<input :value="inputValue" v-on="inputEvents" class="wn-datepicker"/>
									</template>
								</DatePicker>
						</ion-col>

						<ion-col>
							<ion-item class="input-medium ion-no-border">
								<ion-label position="stacked">
									{{ $t("register.country") }}
								</ion-label>
								<WiseCountrySelector
								v-model="form.country"/>
							</ion-item>
						</ion-col>

					</ion-row>
						
				</ion-grid>
			</template>

			<div>
				<!-- Form validation errors -->
				<p v-if="formErrors?.length && false" class="ion-padding-bottom">
				<ul>
					<ion-text color="danger">
						<li v-for="(error, index) in formErrors" :key="index">{{ error }}</li>
					</ion-text>
				</ul>
				</p>
				
				<ion-button 
					size="large"
					color="success"
					expand="block"
					@click="sendUserData()">
					{{ $t("account.editSave") }}
				</ion-button>


			</div>

			<ion-button expand="block" color="danger" size="large" @click="openDeletionAlert()">
				{{ $t("account.deleteUser") }}
			</ion-button>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import axios from 'axios';
import { countryCodes } from '../../WiseCore/countryCodes';
import { alertController, loadingController } from "@ionic/vue";
import { DatePicker} from 'v-calendar'

	// function handlePhoneNumber(code, number) {
	// 		if (number.length === 0) return '';
	// 		if (number.length >= 10 && number[0] == 0) {
	// 			number = number.substring(1, number.length)
	// 		}
		
	// 		return `${ code }${ number }`
	// 	}

export default {
	components: {
		DatePicker
	},
	name: "WiseRegisterForm",
	data() {
		return {
			formErrors: [],
			countryCodes,
			form: {
				firstName: "",
				familyName: "",
				email: "",
				phone: "",
				streetAddress: "",
				postalCode: "",
				city: "",
				birthday: null,
				country: "FI",

			},
			selectedCountry: 'FI',
			loading: false
		}
	},
	
	computed: {
		...mapState({
			user: state => state.user.user,
			settings: state => state.common.settings,
			userLocale: state => state.common.userLocale,
			selectedHost: state => state.common.selectedHost,
			consentCheckboxText: state => state.common.consentCheckboxText
		}),

		...mapGetters({ activeHost: "common/activeHost" }),
		countries() {
			return this.countriesList;
		},
		availableHosts() {
			return this.settings.hosts.filter(row => !row.disableRegistration);
		},
		selectedClubNonWiseClub() {
			if (this.availableHosts.length === 0) return false;

			const clubId = this.form.golfClubId;

			if (clubId == 99999 || clubId == 99998) return true;

			return this.availableHosts.filter(row => row.golfClubId == clubId).length === 0;
		},
	},
	methods: {
		...mapActions({ deleteUser: "user/deleteUser"}),

		async loadingOverlay() {
			this.loading = await loadingController.create({
				cssClass: "wise-login-overlay",
			});
			await this.loading.present();
		},

		async removeAndLogout() {
			try {
				const data = await this.deleteUser();
				if (data.error?.type === "error") {
					throw new Error(data.error.text);
				}
				else {
					await this.$store.dispatch('user/logOut');
					this.$router.replace('/');
					this.loading.dismiss();
				}
			} catch (error) {
				//console.log("deleteUser:", error)
				this.loading.dismiss();
				const alert = await alertController.create({
					mode: 'ios',
					header: `${this.$t("account.userDeletionErrorHeader")}`,
					message:  error + '<br>' + `${this.$t("account.userDeletionErrorDescriptionText")}`,
					buttons: [
						{
							text: this.$t('close'),
							role: 'cancel'
						}
					]
				})
				await alert.present();
			}
		},

		async openDeletionAlert() {
			const alert = await alertController.create({
                        mode: 'ios',
                        header: this.$t("account.deleteUserConfirmationHeader"),
                        message: `${this.$t("account.deleteUserDescriptionText")}`,
                        buttons: [
                            {
                                text: this.$t("cancel"),
                                role: 'cancel'
                                },
                            {
								text: this.$t("confirm"),
								role: 'success',
								handler: async () => {
									this.loadingOverlay();
									//console.log('Trying to remove user');
									this.removeAndLogout();
								}
							}
                        ]
                    });

			await alert.present();
		},

		// parsePhoneNumber(number, countryCodes) {
		// for (var countryCode of countryCodes) {
		// 	if (number.startsWith(countryCode.code)) {
		// 		number = number.substring(countryCode.code.length)
		// 		this.selectedCountry = countryCode.country;
		// 		return number;
		// 	}
		// 	else if ( number.startsWith(0)) {
		// 		number = number.toString();
		// 		number = number.substring(0)
		// 		number = Number(number)
		// 		return number
		// 	}
		// 	return number
		// }
		// },

		// Submits account edit
		async sendUserData() {
			// Format dates to DD.MM.YYYY
			if (this.form.birthday) {
				this.form.dateOfBirth = this.$filters.dateShort(this.form.birthday)
			}
				// Submit edit form
					try {
						//const [ country ] = this.countryCodes.filter(row => row.country === this.selectedCountry)
						const form = {
							...this.form,
							//phone: handlePhoneNumber(country.code, this.form.phone)
						}

						//console.log('sendUserData', form)
						// QUESTION: Should we ask password confirm when update accound data?
						const { data } = await axios.post(`${this.activeHost.ajaxUrl}?appuserdetail=1&appauth=${this.activeHost.appauth}&lang=${this.userLocale}`, form);
						this.formErrors = [];

						if (Array.isArray(data.errors)) {
							this.$_wiseAlert({
								mode: 'ios',
								message: data.errors.join("\n"),
								buttons: [{ text: 'OK' }]
							})
							//data.errors.forEach(error => this.formErrors.push(error))
						} else if (typeof data.errors === 'string') {
							this.$_wiseAlert({
								mode: 'ios',
								message: data.errors,
								buttons: [{ text: 'OK' }]
							})
						}

						if (data.success) {
							// Delete unneeded keys from response to get "plain" user object
							delete data['success', 'debug', 'tempDebug'];
							//console.log(data)
							// Merge current vuex user state and response user object
							Object.assign(this.user, data);
							//console.log(this.user)

							const alert = await alertController.create({
								header: this.$t("account.editSuccess"),
								buttons: ["Ok"]
							});

							alert.onDidDismiss().then(() => {
								this.$router.back()
							})
							return alert.present();
						}
					} catch (e) {
						console.log('appuserdetail', e)
					}

		},

	},
	async mounted() {
		this.form.firstName = this.user?.firstName
		this.form.familyName = this.user?.familyName
		this.form.email = this.user?.email
		this.form.phone = this.user?.phone, //this.parsePhoneNumber(this.user?.phone, countryCodes)
		this.form.streetAddress = this.user?.streetAddress
		this.form.postalCode = this.user?.postalCode
		this.form.city = this.user?.city
		this.form.country = this.user?.country || 'FI'
		this.form.gender = this.user?.gender
		this.form.birthday = this.user?.dateOfBirth ? this.user?.dateOfBirth : null
		this.form.guardianName = this.user?.guardianName,
		this.form.guardianBirthday = this.user?.guardianSSN ? this.user?.guardianSSN : null
		this.form.guardianPhone = this.user?.guardianPhone
		this.form.guardianEmail = this.user?.guardianEmail
	},
};
</script>

<style scoped>

</style>