import { alertController } from '@ionic/vue';
import { formatCurrency } from './services/WiseCurrency.js'
import { dateFormat, formatDateTime, getDateFormat } from './services/WiseDates.js'
import { _locale } from './services/WiseLocale';
import ModalWrapper from './components/ModalWrapper.vue'

let CURRENCY_SYMBOL = '€'; // default, what would be the 'correct' way to change this

function changeCurrencySymbol(symbol) {
	CURRENCY_SYMBOL = symbol;
}

async function wiseAlert(settings) {
	const alert = await alertController
	.create(settings);

	await alert.present();

	return alert;
}

const components = {
	ModalWrapper,
}

export default {
	install(app) {
		Object.keys(components).forEach((name) => {
			const component = components[name];
			app.component(name, component);
		});

		// WiseNetwork globals
		app.config.globalProperties.$_wiseAlert = wiseAlert;
		app.config.globalProperties.$_formatCurrency = formatCurrency;
        app.config.globalProperties.$_CURRENCY_SYMBOL = CURRENCY_SYMBOL;
		app.config.globalProperties.$_changeCurrencySymbol = changeCurrencySymbol;
        app.config.globalProperties.$_formatDateTime = formatDateTime;
        app.config.globalProperties.$_dateFormat = dateFormat;
        app.config.globalProperties.$_getDateFormat = getDateFormat;
		app.config.globalProperties.$_locale = (placeholder, firstUpper = true, injection = null) => {
            const translation = _locale(placeholder, injection);
            
            if (firstUpper) {
                return translation.ucfirst();
            }
            
            return translation;
        };

		// I am deeply sorry for this but legacy requires..
		String.prototype.ucfirst = function() {
			return this.charAt(0).toUpperCase() + this.slice(1);
		}
	}
};
