import settings from '@/WiseSettings.js';
import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import fitnessDefaultImage from '../../../assets/fitness_default_image.png'
import WiseStore from '@/store';
// Used for to populate common state on initial load and reset common state on logout
const getCommonDefaultState = () => {
	return {
		userLocale: null, // populated from main.js on app init
		settings: settings,
		screenMode: null,
		selectedHost: null,
		overrideHost: null, // wisegolf feature, separate idea from having multiple proper hosts
		newsBaseUrlOverride: null,
		loadingArticles: false,
		listArticles: [],
		sliderArticles: [],
		loadMoreArticlesPage: 2,
		articlesLoadedFromHost: null,
		articlesLoadedLocale: null,
		articlesLoadedTimestamp: null,
		consentCheckboxText: null,
		contracts: [],
		loadingContracts: false,
		commonShowAlert: null, // Alert instance to use onDidDismiss etc... when used this.ShowAlert()
		defaultFitnessImage: fitnessDefaultImage, // Default image for front page enrolled events and possible future workout feature
		// FIXME Add default error image
		errorImage: fitnessDefaultImage, // Articles image when error loading image
		// Benefits
		// FIXME Add default benefits image
		defaultBenefitsImage: require("@/assets/icons/icon_discount-percent-line.svg"), // If no image on benefits use this
		loadingBenefits: false,
		benefits: [],
		benefitsLoadedLocale: null,
		benefitsLoadedTimestamp: null,
		generalGymAppSettings: null,
	}
}

export default {
	namespaced: true,
	state: getCommonDefaultState(),
	getters: {
		userLocale: (state) => state.userLocale,
		darkMode: (state) => state.screenMode === 'dark',
		allHosts: (state) => state.settings.hosts,
		selectedHost: (state) => state.selectedHost,
		originalHost: (state) => {
			if (state.selectedHost) {
				const selectedHost = state.settings.hosts.find(host => host.name === state.selectedHost);
				
				if (!selectedHost) {
					console.log('selected host not found', state.selectedHost)
				}

				return selectedHost || state.settings.hosts[0]
			} else {
				// If no host selected return first as default
				return state.settings.hosts[0];
			}
		},
		activeHost: (state, getters) => {
			const { originalHost } = getters;
			
			if (state.overrideHost !== null) {
				return {
					...originalHost,
					...state.overrideHost
				}
			}

			// Add generalAppSettings if available
			if (state.generalGymAppSettings) {
				return {
					...originalHost,
					...state.generalGymAppSettings
				};
			}

			return originalHost;
		},
		// for shorthand usage
		restUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.restUrl;
		},
		ajaxUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.ajaxUrl;
		},
		baseUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.baseUrl;
		},
		ecomUrl: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.ecomUrl;
		},
		appauth: (state, getters) => {
			const { activeHost } = getters;

			return activeHost.appauth;
		}
	},
	mutations: {
		screenMode(state, value) {
			state.screenMode = value;

			if (value === 'dark') {
				document.body.classList.add('dark')
			} else {
				document.body.classList.remove('dark')
			}

			Preferences.set({ key: `screenMode-${settings.appId}`, value })
		},
		mutateCommonStateToDefault(state) {
			const skipReset = ['screenMode', 'selectedHost', 'userLocale']
			const defaultValues = getCommonDefaultState()

			Object.keys(defaultValues).forEach(key => {
				if (skipReset.includes(key)) return;

				state[key] = defaultValues[key]
			})
		},
		mutateCommonShowAlert(state, commonShowAlert) {
			state.commonShowAlert = commonShowAlert;
		},

		mutateNewsBaseUrl(state, newsBaseUrl) {
			state.newsBaseUrlOverride = newsBaseUrl;
		},
		mutateListArticles(state, listArticles) {
			state.listArticles = listArticles;
		},
		mutateMoreListArticles(state, listArticles) {
			state.listArticles = state.listArticles.concat(listArticles);
		},
		mutateLoadingState(state, loadingArticles) {
			state.loadingArticles = loadingArticles
		},
		mutateSliderArticles(state, sliderArticles) {
			state.sliderArticles = sliderArticles;
		},

		mutateSelectedHost(state, selectedHost) {
			state.selectedHost = selectedHost;
			console.log(`%cSelected host is: ${selectedHost}`, 'color: orange; padding: 5px 0')
			WiseStore.dispatch("common/getMobileAppSettings");
		},
		mutateOverrideHost(state, overrideHost) {
			state.overrideHost = overrideHost;
			console.log(`%cSelected override host is: ${overrideHost?.name}`, 'color: lightcoral; padding: 5px 0')
		},
		mutateLoadMoreArticlesPage(state, loadMoreArticlesPage) {
			state.loadMoreArticlesPage = loadMoreArticlesPage;
		},
		mutateArticlesLoadedFromHost(state, articlesLoadedFromHost) {
			state.articlesLoadedFromHost = articlesLoadedFromHost;
		},
		mutateArticlesLoadedLocale(state, articlesLoadedLocale) {
			state.articlesLoadedLocale = articlesLoadedLocale;
		},
		mutateArticlesLoadedTimestamp(state, articlesLoadedTimestamp) {
			state.articlesLoadedTimestamp = articlesLoadedTimestamp;
		},
		mutateConsentCheckboxText(state, consentCheckboxText) {
			state.consentCheckboxText = consentCheckboxText;
		},
		mutateContracts(state, contracts) {
			state.contracts = contracts.rows
		},
		mutateloadingContracts(state, loadingContracts) {
			state.loadingContracts = loadingContracts
		},
		mutateUserLocale(state, userLocale) {
			state.userLocale = userLocale;
		},
		mutateLoadingBenefits(state, loadingBenefits) {
			state.loadingBenefits = loadingBenefits;
		},
		mutateBenefits(state, benefits) {
			state.benefits = benefits;
		},
		mutateBenefitsLoadedLocale(state, benefitsLoadedLocale) {
			state.benefitsLoadedLocale = benefitsLoadedLocale;
		},
		mutateBenefitsLoadedTimestamp(state, benefitsLoadedTimestamp) {
			state.benefitsLoadedTimestamp = benefitsLoadedTimestamp;
		},
		// Used for overwrite settings from DB
		mutateSettings(state, settings) {
			state.settings = settings;
		},
		mutateGeneralAppSettings(state, settings) {
			state.generalGymAppSettings = settings;
		}
	},
	watch: {
		mutateNewsBaseUrl() {
			this.getArticles()
		}
	},
	actions: {
		// Get all settings for current appId from MobileAppSettings. Overwrites defaults in src/WiseSettings.js
		//Fitness settings will be removed from this ajax call at some point!
		async getMobileAppSettings({ getters, commit, state }) {
			const { data } = await axios.get(`${getters.activeHost.ajaxUrl}?getmobileappsettings=1&appid=${state.settings.appId}&appauth=${getters.activeHost.appauth}`);
			let overWriteSettings = data

			// Convert string true/false to boolean and check if string is number (if true and convert to number)
			for (let [key, value] of Object.entries(overWriteSettings)) {
				if(value === "true" || value === true) {
					value = true;
				} else if (value === "false" || value === false) {
					value = false;
				} else if (!isNaN(value)) {
					value = parseInt(value)
				}
				
				overWriteSettings[key] = value
			}

			//Get new fitness settings (2024) from database, turn it into object and assign values.
			const newFitnessSettingsData = await axios.get(`${getters.activeHost.restUrl}settings/`);
			let fitnessSettings = newFitnessSettingsData.data.rows;
			let overWriteFitnessSettings = {};

			fitnessSettings.forEach(item => {
				overWriteFitnessSettings[item.key] = item.value;
			});

			// Convert string true/false to boolean and check if string is number (if true and convert to number)
			for (let [key, value] of Object.entries(overWriteFitnessSettings)) {
				if(value === "true" || value === true) {
					value = true;
				} else if (value === "false" || value === false) {
					value = false;
				} else if (!isNaN(value)) {
					value = parseInt(value)
				}
				
				overWriteFitnessSettings[key] = value
			}

			// Merge settings
			const mergedSettings = {...state.settings, ...overWriteSettings, ...overWriteFitnessSettings }
			commit('mutateSettings', mergedSettings)
			//console.log("Using merged settings: ", mergedSettings)
			commit('mutateGeneralAppSettings', data.generalGymAppSettings)

		},

		// Get articles category (app / slider_app)
		async getArticles({ getters, commit, state }) {
			try {
				commit('mutateLoadingState', true)
				//const sliderArticles = await getSliderArticles(getters, state.userLocale)
				let listArticles = null;
				let newsBaseUrl = state.newsBaseUrlOverride;
				if (newsBaseUrl !== null) {
					listArticles = await getGymSpecificArticles(getters, state.userLocale, 1, 10, newsBaseUrl)
				}
				else {
					listArticles = await getListArticles(getters, state.userLocale)
				}
				//commit('mutateSliderArticles', sliderArticles.data.items)
				commit('mutateListArticles', listArticles.data.items)
				commit('mutateLoadingState', false)
			} catch (error) {
				commit('mutateLoadingState', false)
				console.error("STORE: getArticles() FAILED")
				console.error(JSON.stringify(error))
				return Promise.reject(error)
			}
		},
		// Load more category app articles (@params object can have page and per_page)
		async loadMoreArticles({ getters, commit, state }, params) {
			try {
				const moreListArticles = await getListArticles(getters, state.userLocale, params.page)
				commit('mutateMoreListArticles', moreListArticles.data.items)
				return moreListArticles.data.items.length
			} catch (error) {
				return Promise.reject(error)
			}
		},

		async getConsentText({ getters, commit, state }) {
			try {
				const { data } = await axios.get(`${getters.activeHost.ajaxUrl}?getconsentcheckbox=1&lang=${state.userLocale}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
				commit('mutateConsentCheckboxText', data)
			} catch (error) {
				return Promise.reject(error)
			}
		},

		async getUserBenefits({ getters }) {
			try {
				const response = await axios.get(`${getters.activeHost.restUrl}benefits/user`);
				const data = response.data;
		
				if (data.rows && data.rows.length) {
					data.rows.forEach((benefit) => {
						benefit.imageUrl = benefit.imageId
							? `${getters.activeHost.baseUrl}?action=getContent&type=img&id=${benefit.imageId}`
							: null;
					});
				}
		
				return data;
			} catch (error) {
				console.error("Failed to fetch user benefits:", error);
				throw error;
			}
		},

		async getBenefits({ dispatch, commit, state }) {
			commit('mutateLoadingBenefits', true);
			try {
				// Get benefits using getUserBenefits
				const userBenefits = await dispatch('getUserBenefits');
		
				//  Check if the data is available
				if (userBenefits.rows && userBenefits.rows.length > 0) {
					// Reverse order, so the benefits are in latest to oldest
					const benefitsCategories = [{ categoryId: 'userBenefits', name: 'User Benefits', benefits: userBenefits.rows.reverse() }];
		
					commit('mutateBenefits', benefitsCategories);
					commit('mutateBenefitsLoadedTimestamp', Math.floor(Date.now() / 1000));
					commit('mutateBenefitsLoadedLocale', state.userLocale);
				} else {
					console.warn("No user benefits found.");
				}
		
				commit('mutateLoadingBenefits', false);
			} catch (error) {
				console.error("getBenefits() error:", error);
				commit('mutateLoadingBenefits', false);
				commit('mutateBenefitsLoadedTimestamp', null);
				commit('mutateBenefitsLoadedLocale', null);
				return Promise.reject(error);
			}
		},

		async getContracts({getters, commit}) {
			commit('mutateloadingContracts', true)
			try {
				const { data } = await axios.get(`${getters.activeHost.restUrl}contracts/`)
				//console.log(data.rows);

				if (data.rows) {
					const currentDate = new Date();

					data.rows.forEach( row => {
						if (row.subscriptionEndDate) {
							const endDate = new Date(row.subscriptionEndDate);
							const hasEnded = endDate < currentDate;
							row.hasEnded = hasEnded;
							//console.log(hasEnded); // true if contract has ended, false otherwise
						}

						if (row.subscriptionStartDate) {
							const startDate = new Date(row.subscriptionStartDate);
							const hasStarted = startDate < currentDate;
							row.hasStarted = hasStarted;
						}

						if (row.subscriptionEndDateFixedPeriod && row.subscriptionType === 3) {
							const fixedTermEndsDate = new Date(row.subscriptionEndDateFixedPeriod);
							const fixedTermHasEnded = fixedTermEndsDate < currentDate;
							row.fixedTermHasEnded = fixedTermHasEnded;
						}

						if (row.totalDiscounts) {
							row.totalPrice = row.totalPrice - row.totalDiscounts;
						}
					})

					commit('mutateContracts', data)
				}
				commit('mutateloadingContracts', false)
			} catch (error) {
				console.error("getContracts() error: ", error)
				commit('mutateloadingContracts', false)
			}
		},

		async getBenefitAvailability({ getters, state }, benefitId) {
			try {
				const { data } = await axios.get(`${getters.activeHost.restUrl}benefit/${benefitId}/availability?lang=${state.userLocale}`)
				return data.rows
			} catch (error) {
				console.error("getBenefitAvailability()", error)
				return Promise.reject(error)
			}
		},

		async postUseBenefit({ getters }, benefitId) {
			try {
				const { data } = await axios.post(`${getters.activeHost.restUrl}benefit/${benefitId}/use`)
				console.log("useBenefit", data)
				return data
			} catch (error) {
				console.error("useBenefit()", error)
				return Promise.reject(error)
			}
		},
	}
}

const getGymSpecificArticles = async (getters, locale ='fi-fi', page = 1, perPage = 10, newsBaseUrlOverride = null) => {
		return await axios.get(`${newsBaseUrlOverride}?controller=ajax&appmode=1&method=getLastItems&lang=${locale}&categoryName=app&page=${page}&per_page=${perPage}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
	}

const getListArticles = async (getters, locale = 'fi-fi', page = 1, perPage = 30) => {
	try {
		return await axios.get(`${getters.activeHost.ajaxUrl}?controller=ajax&appmode=1&method=getLastItems2&lang=${locale}&categoryName=app&page=${page}&per_page=${perPage}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
		}
	catch (error) {
		console.error(error)
	}
}

// const getSliderArticles = async (getters, locale = 'fi-fi', page = 1, perPage = 10) => {
// 	try {
// 		return await axios.get(`${getters.activeHost.ajaxUrl}?controller=ajax&appmode=1&method=getLastItems2&lang=${locale}&categoryName=app_slider&page=${page}&per_page=${perPage}&nocache=${Date.now()}&appauth=${getters.activeHost.appauth}`)
// 	} catch (error) {
// 		console.error(error)
// 	}
// }