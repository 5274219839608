<template>
<ion-page>
    <ion-header>
        <ion-toolbar>
            <ion-title>
                <slot name="modal-title"></slot>
            </ion-title>
            <ion-button @click="closeModal()"
                color="primary" 
                fill="outline" 
                size="small" 
                class="ion-float-right">
                <i class="wn-icon closed"></i>
            </ion-button>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <slot :closeModal="closeModal"></slot>
    </ion-content>
</ion-page>
</template>

<script>
import { close } from 'ionicons/icons';
import { modalController } from '@ionic/vue';

export default {
    data: () => ({
        close,
    }),
    methods: {
        closeModal() {
            modalController.dismiss();
            this.$emit('close');
        }
    }
}
</script>