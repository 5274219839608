import { Preferences } from '@capacitor/preferences';
import store from '@/store';
import settings from '@/WiseSettings';

const { state, commit, dispatch } = store;

export default [
    {
        path: 'welcome',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfFront/WelcomePage.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ settings.appId }` })
            const { value: terms } = await Preferences.get({ key: `terms_accepted-${ settings.appId }` });

            if (access_token && terms) {
                next('/golf/front')
            } else {
                next()
            }
        },
    },
    {
        path: 'front',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfFront/FrontPage.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ settings.appId }` })
            const { value: terms } = await Preferences.get({ key: `terms_accepted-${ settings.appId }` });

            if (!access_token || !terms) {
                next('/golf/welcome')
            } else {
                next()
            }
        },
    },
    /*{
        path: 'front',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfFront.vue')
    },*/
    {
        path: 'golfpistelogin',
        component: () => import('@/WiseEcom/EcomReservation/views/GolfPisteLogin.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ settings.appId }` })
            const { value: isGolfPisteToken } = await Preferences.get({ key: `isGolfPisteToken` })
            console.log('golfpistelogin beforeEnter', access_token, isGolfPisteToken)
            
            if (!isGolfPisteToken && access_token) {
                await Preferences.remove({ key: `access_token-${ settings.appId }` })
            }
            /*
            const loggedIn = store.getters['user/loggedIn']
            console.log('loggedIn', loggedIn)
            // in golfpiste login we need the user to get rid of the possible original session
            // because golfpiste sessionToken takes over
            if (loggedIn) {
                await store.dispatch('user/logOut')
            }
            */
            next()
        }
    },
    {
        path: 'proxy',
        component: {
            template: '<h1>LOL proxy!</h1>',
            mounted() {
                console.log('proxy mounted!')
            }
        },
        beforeEnter: async (to, from, next) => {
            const { 
                session_token,
                club_id,
                route
            } = to.query
            const hosts = settings.hosts
            const [ selected ] = hosts.filter(row => row.golfClubId == club_id)
            console.log(
                session_token,
                club_id,
                route,
                selected
            )
            window.forceGolfConsent = true;
            await Preferences.set({ key: `access_token-${ settings.appId }`, value: session_token })
            await Preferences.set({ key: `selectedGolfClub-${ settings.appId }`, value: JSON.stringify(selected.name) })
            /*
            if (route.includes('proxy')) {
                next()
            } else {
                next(route)
            }
            */
            next()
        },
    },
    {
        path: 'products/reservation/:productId',
        component: () => import('@/WiseEcom/EcomReservation/views/ReservationProduct.vue')
    },
    {
        name: 'EcomHostSelectionReservationProduct',
        path: 'products/:host/reservation/:productId',
        component: () => import('@/WiseEcom/EcomReservation/views/ReservationProduct.vue'),
        beforeEnter: async (to, from, next) => {
            const { selectedHost } = state.common;
            const { host } = to.params;
            console.log(host, selectedHost)
            // try to change the host if it is mentioned in the url
            if (host) {
                const hosts = settings.hosts;
                const selected = hosts.filter(row => row.name === host)[0]
                console.log(host, selected)
                // in the case of invalid host, redirect to the front page
                if (!selected) {
                    next('/golf/front');
                }
                // if it is actually not the active host, remove token
                if (host !== selectedHost) {
                    await Preferences.remove({ key: `access_token-${settings.appId}` })
                }
                // this has bugs, maybe it should check the session or something..
                // this should support viewing the calendar without logging in
                try {
                    [
                        document.querySelector('html'),
                        document.querySelector('body')
                    ].forEach(el => {
                        if (el.classList.contains('wisegolf') === false) {
                            el.classList.add('wisegolf')
                        }
                    })
                    const { mode } = to.query

                    if (mode === 'infotv') {
                        const infoTVStyles = document.createElement('style')
                        infoTVStyles.innerHTML = `
                            .infotv .golf-reservation .date-select,
                            .infotv .golf-reservation .resource-select,
                            .infotv ion-back-button {
                                display: none;
                            }
                        `;
                        
                        document.querySelector('html').classList.add('infotv')
                        document.body.append(infoTVStyles)
                    }

                    localStorage['reservationShowName'] = 'true'
                    localStorage['reservationShowPast'] = 'false'

                    const serviceSettings = {
                        baseUrl: selected.baseUrl,
                        restUrl: selected.restUrl,
                        ecomUrl: selected.ecomUrl,
                        ajaxUrl: selected.ajaxUrl,
                        adminUrl: selected.adminUrl,
                        appauth: selected.appauth,
                    }
                    console.log(serviceSettings)
                    
                    await Preferences.set({ key: `selectedHost-${settings.appId}`, value: selected.name });
                    
                    commit('common/mutateSelectedHost', selected.name)
                    commit('res_common/setIsEmbed', true)
                    dispatch('res_ecom/setEcomServiceSettings', serviceSettings)
                    
                    await dispatch('user/validateAccessTokenFromStorage', true)
                } catch(e) {
                    console.log(e)
                }
            }
            
            next()
        },
    },
    {
        path: 'products/reservation',
        component: () => import('@/WiseEcom/EcomReservation/views/ListReservationProducts.vue')
    },
    {
        path: 'products/rangedispencer',
        component: () => import('@/WiseEcom/EcomReservation/views/RangeDispencer.vue')
    },    
    {
        path: 'user/golfreservations',
        component: () => import('@/WiseEcom/EcomReservation/views/ListGolfReservations.vue')
    },
    {
        path: 'user/membershipcode',
        component: () => import('@/WiseEcom/EcomReservation/views/RegisterMembershipCode.vue')
    },
    {
        path: 'article/:id',
        component: () => import('@/WiseEcom/EcomReservation/views/WiseArticle.vue')
    },
]